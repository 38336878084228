import React, { useEffect, useRef, useState } from "react"
import { getMonthUTC } from '../utils/dateHelper'
import { copyToclipboard } from '../utils/clipboardHelper'

export default function BoletosEmitidos({boletos, handleSendAtendimento}) {
  const items = useRef([]);
  const [timeouts, setTimeouts] = useState([]);

  useEffect(() => {
    return () => timeouts.forEach(t => clearTimeout(t));
  }, [timeouts]);

  function handleCopy(boleto, index) {
    copyToclipboard(boleto.codigoBarrasFull);
    items.current[index].innerHTML  = "Código Copiado"
    items.current[index].className = "sb-components__button inline copied";
    const timeout = setTimeout(() => {
      items.current[index].innerHTML  = "Copiar Código"
      items.current[index].className = "sb-components__button inline copy";
    }, 3000);
    setTimeouts(prev => [...prev, timeout]);
  }

  return boletos.map((boleto, index) => 
  <div className="sb-area-logada__das__table__boleto" key={index}>
    <h4>{getMonthUTC(boleto.competencia)}</h4>
    <div className="row">
      <div className="col-4">
        <strong>{boleto.valorTotal.replace(".", ",")}</strong>
        <span>Vencimento<br/>{boleto.dataVencimento}</span>
      </div>
      <div className="col-8 sb-utilities__text-right">
        <img
          src={"data:image/png;base64," + boleto.barCodeBase64}
          className="span12 baseurlopa2"
          alt=""
        />
        <p>{boleto.codigoBarrasFull}</p>
        <button
          ref={el => items.current[index] = el}
          type="button"
          className="sb-components__button inline copy"
          onClick={() => handleCopy(boleto, index)}
          onKeyPress={() => handleCopy(boleto, index)}
          data-sb-acao='clique'
          data-sb-rotulo={`Copiar código - ${boleto.dataVencimento}`}
        >Copiar Código
        </button>
      </div>
    </div>
  </div>)
}