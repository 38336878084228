import React from "react";
import PropTypes from "prop-types";

function SuccessModal({
  closeModal,
  handleAdicionarOutroCnpj
}) {
  return (
    <>
      <div className="sb-components__alert success">
        <strong>CNPJ adicionado com sucesso!</strong>
      </div>
      <div className="sb-components__form__buttons">
        <a
          // href="#modal-vinculo-tag"
          className="sb-components__button inline radius-4 small-btn"
          onClick={closeModal}
        >
          Fechar
        </a>
        <a className="sb-components__button outline radius-4 large-btn" onClick={handleAdicionarOutroCnpj}>
          Adicionar outro CNPJ
        </a>
      </div>
    </>
  );
}

SuccessModal.protoTypes = {
  closeModal: PropTypes.func,
  handleAdicionarOutroCnpj: PropTypes.func
};

export default SuccessModal