import axios from 'axios';

export default function updateToken(keycloak,functionCallback){
    keycloak.updateToken(30).success(function() {
        axios.defaults.headers.common.Authorization = `Bearer ${keycloak.token}`
        functionCallback();
        console.log("Token Atualizado!");
    })
    // .catch(function() {
    //     console.error('Failed to refresh token')
    //     window.location.reload()
    // });
}