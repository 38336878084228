import React, {useEffect, useRef, useState} from 'react'
import {useGlobalState} from '../context/AppContext'
import axios from 'axios'
import $ from 'jquery'

import Child from './child'
import updateToken from '../../../updateToken'

const ModalIncentivo = (props) => {
  const firstRender = useRef(true)

  const perfilCallback = props.callback

  const [state] = useGlobalState()

  const [dominios, setDominios] = useState('')
  const [optionsMenu, setOptionsMenu] = useState('')
  const [optionsRender, setOptionsRender] = useState('select')

  const perfilStorageKey = `_userPerfil-${state.keycloak.tokenParsed.sub}`

  if (props.show) {
    if (firstRender.current) {
      getDominiosPeril(() => $('#modal-incentivo').fadeIn('fast').addClass('active'))
      firstRender.current = false
    } else {
      $('#modal-incentivo').fadeIn('fast').addClass('active')
    }
  } else {
    $('#modal-incentivo').fadeOut('fast').removeClass('active')
  }

  function closeModalIncentivo() {
    const perfil = localStorage.getItem(perfilStorageKey)
    if (perfil) {
      props.onChangeShow && props.onChangeShow(false)
      setOptionsRender('select')
      $("html, body").css("overflow","")
    }else if (optionsMenu){
      $('#alert-choose').hide();
      $('#alert-confirm').show();
    }else{
      $('#alert-confirm').hide();
      $('#alert-choose').show();
    }
  }

  function openModalIncentivo() {
    props.onChangeShow && props.onChangeShow(true)
  }

  function getDominiosPeril(callback) {
    if (dominios === '') {
      axios.get(`${state.env.BACKEND_URL}/v1/perfil/dominios`).then(res => {
        setDominios(
            <div className="sb-modal-dropdown">
              <div className="sb-modal-dropdown__filter">
                <div className="sb-modal-dropdown__filter__item">
                  <button className="sb-modal-dropdown__filter__item__btn">Selecione</button>
                  <ul className="sb-modal-dropdown__filter__content">
                    {res.data.map((dominio, index) => (
                      <li key={index} className="sb-modal-dropdown__filter__item__option" onClick={() => updatePerfilSelect(dominio['codigo'], dominio['description'])}>
                      <label className="sb-modal-dropdown__filter__item__label">{dominio['description']}</label>
                       </li>
                    ))}
                  </ul>
                </div>
              </div>
              <span className="sb-form-error__alert" id="alert-choose" style={{display:"none"}}>Escolha um perfil para continuar</span>
              <span className="sb-form-error__alert" id="alert-confirm" style={{display:"none"}}>Confirme o perfil para continuar</span>
            </div>
        )
      }).then(() => {
        if (typeof callback === 'function') {
          callback()
        }
      })
    }
  }

  function updatePerfilSelect(value, description) {
    cleanAlerts();
    setOptionsMenu(
      <div>
        <a className='sb-components-button inline small radius-5 text-center'
           data-sb-acao='clique' data-sb-rotulo={`Confirmar - ${description}`}
           onClick={() => setUserPerfil(value)}
           onKeyPress={() => setUserPerfil(value)}>Confirmar</a>
      </div>
    )
  }

  function setUserPerfil(idDominio) {
    if (idDominio !== '') {
      updateToken(state.keycloak,()=>{
        axios.post(`${state.env.BACKEND_URL}/v1/perfil/usuarios/${idDominio}`)
        .then(res => {
          localStorage.setItem(perfilStorageKey, JSON.stringify(res.data))
          perfilCallback(res.data)
          setOptionsRender('selected')
          setTimeout(function(){window.location.reload();},3000);
        })
        .catch(() => {
          setOptionsRender('selected')
        })
      });

    }
  }

  function cleanAlerts(){
    $('#alert-confirm').hide();
    $('#alert-choose').hide();
  }

  function verifyUserPerfil() {
    const perfil = localStorage.getItem(perfilStorageKey)
    if (perfil) {
      perfilCallback(JSON.parse(perfil))
      return
    }
    axios.get(`${state.env.BACKEND_URL}/v1/perfil/usuarios`)
      .then(res => {
        localStorage.setItem(perfilStorageKey, JSON.stringify(res.data))
        perfilCallback(res.data)
      })
      .catch(err => {
        if (err.response && err.response.status === 404) {
          openModalIncentivo()
        }
      })
  }

  useEffect(verifyUserPerfil, [])

  return (
    <div className='sb-modal sb-modal-dialog' id='modal-incentivo' data-sb-categoria="modal-incentivo-perfil">
      <Child
        dominios={dominios}
        optionsMenu={optionsMenu}
        optionsRender={optionsRender}
        closeModalIncentivo={closeModalIncentivo}/>
    </div>
  )
}

export default ModalIncentivo
