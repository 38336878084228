import React from "react"
import { getMonthUTCByDate } from '../../utils/dateHelper'

export default function BoletosPago({boletos}) {
  return boletos.map((boleto, index) => 
  <div className="row" key={index}> 
    <div className="col-md-6">
      {getMonthUTCByDate(new Date(boleto.dtCompetenciaLocal))}
    </div>
    <div className="col-md-6 sb-utilities__text-right">
      <span className="sb-area-logada__das__table__body__icon sb-das-pago">Pago</span>
    </div>
  </div>)
}