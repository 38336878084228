import React from "react";
import PropTypes from "prop-types";

/**
 * 
 * @param cadastrarEmpresaRef    
 * @param cadastrarEmpresaCpe    
 * @param setCnpjInexistenteCpe  
 * @returns 
 */
function CadastrarEmpresaModal({
  cadastrarEmpresaRef,
  cadastrarEmpresaCpe,
  setModalState,
}) {
  return (
    <>
      <p style={{ fontSize: "1rem", lineHeight: "1.5" }}>
        Infelizmente não encontramos sua empresa em nossa base. Gostaria de
        cadastrá-la?
      </p>
      <div className="sb-components__form__buttons">
        <span
          onClick={cadastrarEmpresaCpe}
          className="sb-components__button inline radius-4"
          id="cadastrarEmpresa"
          ref={cadastrarEmpresaRef}
        >
          Sim, cadastrar empresa
        </span>
        <span
          onClick={() => setModalState()}
          className="sb-components__button outline radius-4"
        >
          Cancelar
        </span>
      </div>
    </>
  );
}

CadastrarEmpresaModal.protoTypes = {
  cadastrarEmpresaRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  cadastrarEmpresaCpe: PropTypes.func,
  setCnpjInexistenteCpe: PropTypes.func,
};
export default CadastrarEmpresaModal;
