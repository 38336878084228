import React, {useEffect, useState} from 'react'
import EmpresasVinculadas from '../../modulos/empresasVinculadas'
import Notifications from '../../modulos/notifications'
import {useGlobalState} from '../context/AppContext'
import Axios from 'axios'
import {getCacheDate} from '../../../date';
import updateToken from '../../../updateToken'
import MenuLateral from '../../modulos/menuLateral'

export default function UserInfo(props) {
  const perfil = props.perfil && props.perfil.description
  const hideEmpresas = props.perfil && props.perfil.labelDominio === 'PERFIL_ABRIR_EMPRESA'
  const changePerfil = props.changePerfil

  const [state] = useGlobalState()

  const [minhaContaURL, setMinhaContaURL] = useState('');
  const [avaURL, setAvaURL] = useState('');
  const [modalPerfil, setModalPerfil] = useState(false)


  function exibeCadastroPJ() {
    props.exibeCadastroPJ && props.exibeCadastroPJ()
  }

  function updateTokenRedirect(url){

    updateToken(state.keycloak,function(){
      window.open(url, '_blank');
    });

  }

  useEffect(() => {
    Axios.get('/keycloak.json?v='+getCacheDate()).then(config => {
      setMinhaContaURL(config.data.url + "/realms/" + config.data.realm + "/account?referrer=" + config.data.clientId + "&referrer_uri=" + window.location.href)
      setAvaURL(state.env.EAD_URL.endsWith('/') ? state.env.EAD_URL : state.env.EAD_URL + '/')
    })
    .catch(err => {
      console.error('Initialization error', err)
    });
  }, [])

  return <div className="sb-area-logada__menu-lateral__content">
    <MenuLateral perfil={props.perfil} changePerfil={props.changePerfil} exibeCadastroPJ={exibeCadastroPJ}/>
  </div>
}
