import { useEffect } from 'react'
import Axios from 'axios'
import { useGlobalState } from '../../components/shared/context/AppContext'

export default function DownloadSbrtPdf(props) {

  const [state, dispatch] = useGlobalState()

  useEffect(() => {
    let url = state.env.BACKEND_URL
    if (!url.endsWith('/')) {
      url += '/'
    }
    download(url);
  }, [])

 function download(url) {
    try {
      Axios.get(`${url}v1/sbrt/getPdfFile/${props.match.params.id}`).then((response) => {
        const downloadLink = document.createElement('a')
        downloadLink.href = `data:application/pdf;base64,${response.data}`
        downloadLink.download = `resposta.pdf`
        downloadLink.click()
      });
    } catch (e) {
      console.error('Ocorreu um erro: ' + e)
    }
  }

  return null;
}
