import React, {useEffect} from 'react'
import './style.css'

const NotFound = () => {

  useEffect(() => {
    document.body.style.setProperty('cursor', 'unset')
    document.body.style.setProperty('opacity', '1', 'important')
    document.body.style.setProperty('position', 'fixed', 'important')
    document.body.style.setProperty('height', '100%', 'important')
  }, [])

  return (
    <>
      <link rel="dns-prefetch" href="http://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap" type="text/css"/>
      <link rel="stylesheet" href="https://sebrae.com.br/Sebrae/System/css/DBMaster.css"/>
      <link rel="stylesheet" href="https://sebrae.com.br/Sebrae/System/css/global.css"/>

      <div className="sb-notfound">
        <section className="container-manutencao clearfix">
          <div className="logo-manutencao">
            <img src="https://sebrae.com.br/Sebrae/System/img/logo-manutencao.png" alt=""/>
          </div>
          <div className="box-descricao-manutencao">
            <img src="https://sebrae.com.br/Sebrae/System/img/sad.png" width="30" height="40" alt="Sad face"/>
            <h2>Ops!</h2>
            <div className="bt-tente-novamente">Página não encontrada</div>
            <p>A página que você procura não existe.</p>
          </div>
        </section>
      </div>
    </>
  )
}

export default NotFound
