import React from "react"
import { getMonthUTC, getMonthUTCByDate, formatDate } from '../../utils/dateHelper'

export default function BoletosNaoPago({
  boletos,
  maxBoletos,
  handleCheck
}) {
  return boletos.map((boleto, index) => index < maxBoletos && 
  <tr key={index}>
    {!boleto.isDisponivel? 
    <>
    <td>
      <input 
        key={`${index}-${boleto.dtCompetenciaLocal}`}
        data-sb-acao="detalhes - boleto"
        data-sb-rotulo={`${getMonthUTCByDate(new Date(boleto.dtCompetenciaLocal))} - ${formatDate(new Date(boleto.dtCompetenciaLocal))} - ${boleto.situacao} `}
        disabled={true}
        defaultChecked={false}
        onClick={() => {}}
        type="checkbox"
        />
    </td>
    <td>
      <strong>{getMonthUTCByDate(new Date(boleto.dtCompetenciaLocal))}</strong>
      <span>Vencimento</span>
      <p>-</p>
    </td>
    <td>R$ - </td>
    <td>
      <span className="sb-area-logada__das__table__body__icon sb-das-indisponivel">Indisponivel</span>
    </td>
    </>
    :
    <>
    <td>
      <input 
        id={boleto.isDisponivel ? 'boletos-das-checkbox' : ''}
        key={`${index}-${boleto.codigoBarrasFull}`}
        data-sb-acao="detalhes - boleto"
        data-sb-rotulo={`${getMonthUTC(boleto.dataVencimento)} - ${boleto.dataVencimento} - ${boleto.situacao} `}
        name={boleto.isDisponivel ? boleto.codigoBarrasFull : ''}
        disabled={!boleto.isDisponivel}
        defaultChecked={boleto.situacao === 'atrasado'}
        onClick={handleCheck}
        type="checkbox"
      />
    </td>
    <td>
      <strong>{getMonthUTC(boleto.competencia)}</strong>
      <span>Vencimento</span>
      <p>{boleto.dataVencimento}</p>
    </td>
    <td>R$ {boleto.valorTotal.replace('.', ',')}</td>
    <td>
    {{
      'atrasado': <span className="sb-area-logada__das__table__body__icon sb-das-atrasado">Atrasado</span> ,
      'a vencer': <span className="sb-area-logada__das__table__body__icon sb-das-a-vencer">A vencer</span>,
      'indisponivel': <span className="sb-area-logada__das__table__body__icon sb-das-indisponivel">Indisponivel</span>
    }[boleto.situacao]}
    </td>
  </>}
  </tr>)
}
