function getMonthUTC(data) {
  const num = data.split("/").reverse();
  const date = new Date(num[0], num[1] - 1, num[2]);
  const month = date.toLocaleString("default", { month: "long" });
  return month.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
}
function getNewDate (data) {
  const num = data.split("/").reverse();
  return new Date(num[0], num[1] - 1, num[2]);
}
function getMonthUTCByDate(date) {
  const month = date.toLocaleString("default", { month: "long" });
  return month.charAt(0).toUpperCase() + month.slice(1);
}
function formatDate(date = new Date()) {
  return `${date.getDay()}/${date.getMonth()+1}/${date.getFullYear()}`
}
export {
  getMonthUTC,
  getMonthUTCByDate,
  getNewDate,
  formatDate
};
