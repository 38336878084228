import React, { useState, useEffect } from 'react';
import { useGlobalState } from "../../shared/context/AppContext";
// import ModalVinculoEmpresa from "../../shared/modalVinculoEmpresa";
// import ModalIncentivo from "../../shared/incentivo";
// import axios from "axios";
import UserInfo from '../../shared/userInfo'
// import ModalCadastroPJ, {dictMessagesPJ} from '../../shared/modalCadastroPJ'
// import ModalDesvincularPJ from '../../shared/modalDesvincularPJ'
// import Banner from '../../shared/area-logada/banner-rotativo'
import ModalManager from '../../shared/modal-manager';
import WhatsappWidget from './WhatsappWidget';

const propp = {
  style:{'position':"fixed", "zIndex": 1000},
  'channel-id':"webchat_portal_cursos_atendimento_sebrae",
  'welcome-message':"Acesse%20nosso%20chatbot%2C%20fa%C3%A7a%20cursos%2C%20encontre%20o%20endere%C3%A7o%20do%20sebrae%20mais%20pr%C3%B3ximo%20ou%20fale%20com%20um%20atendente!",
  'start-button':"Come%C3%A7ar!",
  'start-message':"Oi",
  'position':"bottom-right",
  'theme-header-destak-color':"#005eb8",
  'theme-fab-background-color':"#005eb8",
  'theme-header-background-color':"#ffffff",
  'theme-header-foreground-color':"#005eb8",
  'theme-header-destak-color':"#005eb8",
  'theme-chat-background-color':"#ffffff",
  'theme-chat-foreground-color':"#005eb8",
  'theme-chat-destak-color':"#005eb8",
  'theme-chat-input-message-background-color':"#ffffff",
  'theme-chat-input-message-foreground-color':"#005eb8",
  'theme-chat-outgoing-balloon-background-color':"#ffffff",
  'theme-chat-outgoing-button-background-color':"#ffffff",
  'theme-chat-outgoing-button-foreground-color':"#005eb8",
  'theme-chat-outgoing-balloon-foreground-color':"#005eb8",
  'theme-chat-incoming-balloon-background-color':"#005eb8",
  'theme-chat-incoming-balloon-foreground-color':"#ffffff",
}

const Logada = () => {
  // const [banners, setBanners] = useState([])
  // const [bannersLoaded, setBannersLoaded] = useState(false)
  // const [wemUrl, setWemUrl] = useState('');

  const [state, dispatch] = useGlobalState()

  const [modalPJShow, setModalPJShow] = useState(false)

  const [perfil, setPerfil] = useState(null)
  const [modalPerfil, setModalPerfil] = useState(false)

  const [helpHidden, setHelpHidden] = useState(false)

  useEffect(() => {
    let isRendered = state.isRendered
    isRendered['modulo1'] = true
    dispatch({isRendered: isRendered})
    if(document.cookie.includes('icons-first-interaction=true')) {
      setHelpHidden(true)
    }
  }, [])

  // function getBanners(baseUrl) {
  //   axios.get(baseUrl + '/v1/bannerRotativo')
  //     .then(res => {
  //       if (res && res.data) {
  //         setBanners(res.data)
  //         setBannersLoaded(true)
  //       }
  //     })
  //     .catch()
  // }
  function handleOpenChatOnce() {
    if(!helpHidden) {
        document.cookie = "icons-first-interaction=true; 0; path=/";
        setHelpHidden(true)
    }
  }

  function perfilCallback(perfil) {
    setPerfil(perfil)
  }

  return (
    <section className="sb-container" >
      <div className="sb-area-logada__menu-lateral" data-sb-categoria={`menu-area-logada - ${window.location.href}`}>
        <div className="sb-components-breadcrumb">
          <ul>
            <li><a href="#">Página Inicial </a></li>
            <li> Meu Mural</li>
          </ul>
        </div>
        <UserInfo perfil={perfil} changePerfil={() => setModalPerfil(true)} exibeCadastroPJ={() => setModalPJShow(true)}/>
      </div>

      <ModalManager
        perfil={perfil}
        modalPJShow={modalPJShow}
        setModalPJShow={setModalPJShow}
        perfilCallback={perfilCallback}
        modalPerfil={modalPerfil}
        setModalPerfil={setModalPerfil}
      />

      {/* <ModalCadastroPJ perfil={perfil} show={modalPJShow} onChangeShow={setModalPJShow}/>
      <ModalDesvincularPJ/>
      <ModalIncentivo  callback={perfilCallback} show={modalPerfil} onChangeShow={setModalPerfil}/>
      <ModalVinculoEmpresa setModalPJShow={setModalPJShow}/> */}
      {
        state.env.REACT_APP_WEM_URL && state.env.REACT_APP_WEM_URL.includes("conteudoh")?
        <script src='https://chatbot-webchat.homolog.sebrae.com.br/web-component/webchat-embed.js'></script>
        :
        <script src='https://chatbot-webchat.sebrae.com.br/web-component/webchat-embed.js'></script>
      }
      <div className="sb-fale-conosco__icons">
          <webchat-embed {...propp}>
          </webchat-embed>
          <WhatsappWidget />
      </div>
      <div class="sb-fale-conosco__float" onClick={() => handleOpenChatOnce()}>
          <div class="sb-fale-conosco__float__floating">
              <a>
                  <p class="sb-fale-conosco__float__floating__text" hidden={helpHidden}>Posso Ajudar?</p>
              </a>
          </div>
      </div>

    </section>

  )
}

export default Logada
