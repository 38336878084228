import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {useGlobalState} from '../../shared/context/AppContext'

export default function Notifications() {
  const [state] = useGlobalState()

  const [notificacoes, setNotificacoes] = useState([])
  
  useEffect(getNotificacoesUsuario, [])

  function informacoesUsuario() {
    const estados = {
      'AC': 1,
      'AL': 2,
      'AM': 4,
      'AP': 3,
      'BA': 5,
      'CE': 6,
      'DF': 7,
      'ES': 8,
      'GO': 10,
      'MA': 11,
      'MG': 14,
      'MS': 13,
      'MT': 12,
      'PA': 15,
      'PB': 16,
      'PE': 18,
      'PI': 19,
      'PR': 17,
      'RJ': 20,
      'RN': 21,
      'RO': 23,
      'RR': 9,
      'RS': 22,
      'SC': 25,
      'SE': 27,
      'SP': 26,
      'TO': 24,
    }
    
    return {
      sexo: state.keycloak.tokenParsed.genero === 'feminino' ? 117 : 115,
      uf: estados[state.keycloak.tokenParsed.uf.trim()],
      cadastro: 'empresas' in state.keycloak.tokenParsed ? 114 : 112,
    }
  }

  function getNotificacoesUsuario() {
    const infoUsuario = informacoesUsuario()
    axios.get(`${state.env.BACKEND_URL}/v1/notificacoes/${state.keycloak.tokenParsed.sub}/${infoUsuario.uf}/${infoUsuario.sexo}/${infoUsuario.cadastro}`)
      .then(response => {
        setNotificacoes(response.data)
      })
      .catch(() => {
        setNotificacoes([])
      })
  }

  function marcarNotificacaoLida(event, notificacaoId) {
    event.preventDefault()
    axios.get(`${state.env.BACKEND_URL}/v1/notificacoes/lembretes/add/${state.keycloak.tokenParsed.sub}/${notificacaoId}`)
      .then(() => {
        getNotificacoesUsuario()
      })
      .catch(err => console.log('ERROR: ' + err))
  }

  return <div style={{zIndex: 0}} className="sb-area-logada__header__user__notificacoes">
    <span className="sb-area-logada__header__user__notificacoes__open">
      {notificacoes.length > 0
        ? <strong>{notificacoes.length}</strong>
        : <></>
      }
    </span>
    <div className="sb-area-logada__header__user__notificacoes__content">
      <ul>
        {notificacoes.length === 0
          ? <li><p>Sem notificações</p></li>
          : notificacoes.map(notificacao => (
            <li key={notificacao.id}>
                        <span onKeyPress={(e) => marcarNotificacaoLida(e, notificacao.id)}
                              onClick={(e) => marcarNotificacaoLida(e, notificacao.id)}/>
              <p>{notificacao.texto}</p>
              {
                notificacao.link != null
                  ? <div dangerouslySetInnerHTML={{__html: notificacao.link}}/>
                  : <></>
              }
            </li>
          ))
        }
      </ul>
    </div>
  </div>
}
