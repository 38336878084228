import React, { useContext, useEffect, useState } from "react";
import FormularioDAS from "../FormularioDAS/FormularioDAS";
import ModuloDasContext from "../ModuloDasContext";
import BoletosEmitidos from './BoletosEmitidos';
import { pushAndCheck } from "../../../shared/utils/ls-boletos";

export default function ListagemBoletosEmitidos({
  handleFormulario,
  back
}) {
  const {formulario, checkedBoletos, empresa, sendAtendimento} = useContext(ModuloDasContext);

  useEffect(() => {
	  gerarAtendimentosBoletosEmitidos();
  }, [])

  function handleSendAtendimento(boleto) {
    if(pushAndCheck(boleto.cnpj + boleto.competencia)) {
      sendAtendimento({
        tipo: "Serviços Empresariais",
        tema: "Boleto DAS",
        titulo: `Mês ${boleto.competencia.split("/")[1]}`,
        url: Buffer.from(window.location.href).toString('Base64'),
        codInstrumento: 9,
        dtInicio: new Date().getTime(),
        cnpj_das: boleto.cnpj,
        dt_competencia_das: boleto.competencia
      });
    }
  }


  function downloadPdfs() {
    checkedBoletos.forEach(boleto => {
      const downloadLink = document.createElement("a");
      downloadLink.href = `data:application/pdf;base64,${boleto.pdfBase64}`;
      downloadLink.download = `DAS_${boleto.competencia}.pdf`;
      downloadLink.click();
    })
  }

  function gerarAtendimentosBoletosEmitidos() {
    const checkedBoletosMap = new Map();
    for(const boleto of checkedBoletos) {
      checkedBoletosMap.set(boleto.cnpj + boleto.competencia, boleto);
    }
    let index = 0;
    checkedBoletosMap.forEach((boleto, _) => {
      setTimeout(() => handleSendAtendimento(boleto), 300*index )
      index++;
    });
  }

  return (
  <div className="sb-area-logada__das__item active">
    <div className="row align-items-center">
      <div className="col-md-5">
        <div className="sb-area-logada__das__text">
          <button
            type="button" 
            className="sb-area-logada__das__voltar"
            onClick={() => back(true)}
            onKeyPress={() => back(true)}
          >Voltar
          </button>
          <h3 className="sb-das-h32">Histórico de pagamentos</h3>
          <FormularioDAS handleFormulario={handleFormulario} 
            Button={
              <button
              type="button"
              className="sb-components__button inline radius-4"
              data-sb-acao="entrada-de-dados - boleto"
              data-sb-rotulo={`Atualizar - ${formulario.ano}`}
              disabled>
              Atualizar
            </button>}
          />
          
        </div>
      </div>
      <div className="col-md-7">
        <div className="sb-area-logada__das__table">
          <div className="sb-area-logada__das__table__head">
            <div className="sb-area-logada__das__table__head__flex">
              <p><strong>CNPJ:</strong> {empresa.cnpj}</p>
              <p><strong>Nome:</strong> {empresa.nome}</p>
              <p><strong>Ano:</strong> {empresa.ano}</p>
            </div>
          </div>
          <div className="sb-area-logada__das__table__body">
            {!!checkedBoletos && <BoletosEmitidos boletos={checkedBoletos} handleSendAtendimento={handleSendAtendimento}/>}
          </div>
          <div className="sb-area-logada__das__table__foot">
            <button
              type="button"
              className="sb-components__button inline radius-4"
              onClick={downloadPdfs}
              onKeyPress={downloadPdfs}
              data-sb-acao='clique'
              data-sb-rotulo='Baixar todos'
            >Baixar todos
            </button>
            <button
              type="button"
              className="sb-components__button btn-back radius-4"
              onClick={() => back(false)}
              onKeyPress={() => back(false)}
            >Voltar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>)
  
}