import {useState} from "react";
import {cleanCnpj} from '../utils/cnpjHelper'

export default function useFormulario() {
  const [empresa, setEmpresa] = useState({
    cnpj: '', estado: '', ano: ''
  })
  const [formulario, setFormulario] = useState({
    cnpj: '', estado: '', ano: '', isLoading: false
  });

  function handleFormulario(name, value) {
    if(name==='cnpj') value = cleanCnpj(value);
    setFormulario(prevState => {
      return {
        ...prevState,
        [name]: value
      }
    });
  }

  function handleEmpresa(name, value) {
    if(name==='cnpj') value = cleanCnpj(value);
    setEmpresa(prevState => {
      return {
        ...prevState,
        [name]: value
      }
    });
  }

  function flushEmpresa() {
    setEmpresa(prevState => {
      return {
        ...prevState, 
        cnpj: formulario.cnpj,
        estado: formulario.estado, 
        ano: formulario.ano
      }
    })
  }

  return [
    {empresa, setEmpresa, handleEmpresa, flushEmpresa},
    {formulario, setFormulario, handleFormulario}
  ];
}