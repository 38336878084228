import React, {useEffect, useState} from 'react'
import {useGlobalState} from '../../context/AppContext'
import Axios from 'axios'
import OwlCarousel from "react-owl-carousel";

const Destaques = (props) => {

  const {keycloak} = props

  const [state, dispatch] = useGlobalState()

  const [urlWem, setUrlWem] = useState()
  const [uf] = useState(keycloak.tokenParsed.uf)
  const [bannersAll, setBannersAll] = useState([])
  const [loaded, setLoaded] = useState(false)
  function reqDestaques(url, uf) {
    const estados = {
      'AC': '1', 'AL': '2', 'AM': '4', 'AP': '3', 'BA': '5', 'CE': '6',
      'DF': '7', 'ES': '8', 'GO': '10', 'MA': '11', 'MG': '14', 'MS': '13',
      'MT': '12', 'PA': '15', 'PB': '16', 'PE': '18', 'PI': '19', 'PR': '17',
      'RJ': '20', 'RN': '21', 'RO': '23', 'RR': '9', 'RS': '22', 'SC': '25',
      'SE': '27', 'SP': '26', 'TO': '24',
    }

    Axios.get(`${url}/v1/destaquesEstaduais?codUf=${estados[uf]}`)
      .then(res => {
        setBannersAll(res.data)
        let isRendered = state.isRendered
        isRendered['modulo6'] = true
        dispatch({isRendered: isRendered})
        setLoaded(true)
        window.sbLazyImages()
      })
      .catch(() => {
        let isRendered = state.isRendered
        isRendered['modulo6'] = true
        dispatch({isRendered: isRendered})
      })
  }

  useEffect(() => {
    setUrlWem(state.env.REACT_APP_WEM_URL)
    reqDestaques(state.env.BACKEND_URL, uf)
  }, [])


  return loaded ?
    <section className="sb-section-carousel-destaques-3-items geolocation-component tagged-gtm-carousel"
             data-sb-categoria="PS-DV-HOME-DESTAQUES-ESTADUAIS">
      <div className="tagged-gtm-carousel sb-container">
        <h2>
          Destaques do seu estado
        </h2>
        <div className="sb-components-carousel overflow-x-inherit-md">
          <OwlCarousel
            className="owl-carousel owl-theme sb-carousel-destaques-3-items"
            margin={24}
            navText={['<span class="sb-ico-angle-left"></span>', '<span class="sb-ico-angle-right"></span>']}
            autoHeightClass={'owl-height'}
            nav={true}
            responsive={{0: {items: 1,slideBy: 1,autoWidth: true,margin: 24}, 1025: {items: 3, slideBy: 3, nav: true}}}
            dangerouslySetInnerHTML={{ __html: bannersAll }}
          />
        </div>
      </div>
    </section>
    :
    <div className="sb-loading"><span/></div>
}
export default Destaques
