import React from "react";
import PropTypes from "prop-types";

function EmpresasGrandeMedioPorte({
  empresaName,
  empresaPorte,
  modalPjInfoRef,
  isEmpresaParceira
}) {
  function handleIsEmpresaParceira(event, isParceira) {
    event.preventDefault()
    isEmpresaParceira(isParceira);
  }
  return (
    <div
      className="modal-cad-pj__info"
      hidden
      id="modal-pj-info"
      ref={modalPjInfoRef}
    >
      <span>Empresas medio e grande porte</span>
      <p>
        O atendimento do Sebrae é destinado a pequenos negócios. A empresa{" "}
        <strong>{empresaName}</strong> é de {empresaPorte} porte. Você está
        utilizando os serviços do Sebrae porque possui parcerias que atuam em
        benefício dos pequenos negócios?
      </p>
      <a
        href="#"
        onClick={(e) => handleIsEmpresaParceira(e, true)}
        onKeyPress={(e) => handleIsEmpresaParceira(e, true)}
        className="sb-components__button inline radius-4 disable"
      >
        Sim esta empresa é parceira
      </a>
      <a
        href="#"
        onClick={(e) => handleIsEmpresaParceira(e, false)}
        onKeyPress={(e) => handleIsEmpresaParceira(e, false)}
        className="sb-components__button outline radius-4"
      >
        Não, essa empresa não é parceira
      </a>
    </div>
  );
}

EmpresasGrandeMedioPorte.protoTypes = {
  empresaName: PropTypes.string,
  empresaPorte: PropTypes.string,
  modalPjInfoRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  isEmpresaParceira: PropTypes.shape({ current: PropTypes.instanceOf(Element) })
}

export default EmpresasGrandeMedioPorte;
