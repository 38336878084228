import React from "react";
import ErrorModal from "../../shared/modalVinculoEmpresa/ErrorModal";

function DeletedModal({
  nomeFantasia,
  razaoSocial,
  cnpj,
  isOpen,
  handleAdicionarOutroCnpj
}) {
  function formatCnpj(cnpj = "") {
    if (!cnpj || cnpj.length !== 14) return cnpj;
    return cnpj.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      "$1.$2.$3/$4-$5"
    );
  }

  return (
    <>
      <div
        style={isOpen? {display: 'block'}: {}}
        data-sb-categoria="modal de cadastro PJ"
        className={`sb-components__modal ${isOpen? 'active' : ''}`}
        id="modal-cad-pj"
      >
        <div className="sb-components__modal__wrapper">
          <div className="sb-components__modal__wrapper__content">
            <span className="sb-components__modal__close" onClick={() => window.location.reload()} data-sb-acao="clique" data-sb-rotulo="Fechar - ícone"></span>
            <span className="sb-components__modal__wrapper__content__title__h1 sb-components__modal__wrapper__content__title__h1-blue">
              {cnpj? `${nomeFantasia ?? razaoSocial} ${formatCnpj(cnpj)}` : 'Adicionar CNPJ'}
            </span>
            <div className="sb-utilities__clearfix">
            <ErrorModal
              message={
                "CNPJ inativo/baixado na Receita Federal! O atendimento do Sebrae a empresas é destinado apenas a CNPJs com situação cadastral ativa"
              }
              closeModal={() => window.location.reload()}
              handleAdicionarOutroCnpj={handleAdicionarOutroCnpj}
            />
          </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeletedModal