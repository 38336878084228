import { useState } from "react";

export default function useStep() {
  const [step, setStep] = useState(1);

  function next() {
    if(step === 1) setStep(2)
    else if (step === 2) setStep(3)
  }

  function back(toTheBeginning) {
    if(toTheBeginning || step == 2) {
      setStep(1);
    }else if(step == 3) {
      setStep(2);
    } 
  }

  return {step, next, back};
}