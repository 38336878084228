import React, { useContext, useEffect, useState } from "react";
import { useGlobalState } from '../../../shared/context/AppContext';
import BoletosNaoPago from "./view/BoletosNaoPago";
import BoletosPago from "./view/BoletosPago";
import FormularioDAS from "../FormularioDAS/FormularioDAS";
import { validarCNPJ } from '../utils/cnpjHelper'
import ModuloDasContext from "../ModuloDasContext";
import ModalCadastroPJ from "../../../shared/modalCadastroPJ";

export default function ListagemBoletos({
  handleFormulario,
  handleEmpresa,
  setBoletos,
  fetchBoletos,
  next,
  back,
  setCheckedBoletos,
  keycloak, 
  empresasCPE
}) {
  const [state] = useGlobalState();
  const {formulario, empresa, boletos, checkedBoletos} = useContext(ModuloDasContext);
  const [maxBoletos, setMaxBoletos] = useState(3);
  const [error, setError] = useState('');
  const [shouldDisableCnpj, setShouldDisableCnpj] = useState(true);

  const BOLETO_CHECKBOX = "#boletos-das-checkbox";
  const ALL_BOLETO_CHECKBOX = "boletos-das-checkbox-all";

  const emitirBoletosBloqueadoRN = checkedBoletos.length === 0;
  const atualizarBloqueadoRN = formulario.cnpj === empresa.cnpj && formulario.ano === empresa.ano || formulario.isLoading;

  useEffect(() => handleAtualizar(), [])
  useEffect(() => updateInformacoes(), [boletos])
  useEffect(() => handleCheck(), [boletos, maxBoletos])

  function handleCheck() {
    const list = [];
    const checkboxes = document.querySelectorAll(BOLETO_CHECKBOX);
    checkboxes.forEach(item => {
      if(item.checked){
        const boleto = boletos.naoPagos.find(b => b.codigoBarrasFull === item.name);
        if(!!boleto) list.push(boleto);
      }}
    )
    setCheckedBoletos(list);
    const allCheckbox = document.getElementById(ALL_BOLETO_CHECKBOX);
    if(!!checkboxes.length) {
      allCheckbox.checked = !!list.length && checkboxes.length === list.length 
      allCheckbox.disabled = false; 
    } else {
      allCheckbox.checked = false;
      allCheckbox.disabled = true; 
    }
  }
  
  function handleCheckAll() {
    const isChecked = document.getElementById(ALL_BOLETO_CHECKBOX).checked;
    document.querySelectorAll(BOLETO_CHECKBOX).forEach(item => item.checked = isChecked);
    handleCheck();
  }

  function updateInformacoes() {
    if(!!boletos && boletos.naoPagos && !!boletos.naoPagos.length) {
      const boletoN = boletos.naoPagos.find(b => !!b.razaoSocial);
      if(!!boletoN) {
        handleFormulario('nome', boletoN.razaoSocial);
        handleEmpresa('nome', boletoN.razaoSocial);
        handleEmpresa('cnpj', formulario.cnpj);
        return;
      } 
    } 
    if(!!boletos && !!boletos.pagos && !!boletos.pagos.length) {
      const boletoP = boletos.pagos.find(b => !!b.razaoSocial);
      if(!!boletoP) {
        handleFormulario('nome', boletoP.razaoSocial);
        handleEmpresa('nome', boletoP.razaoSocial);
        handleEmpresa('cnpj', formulario.cnpj);
        return;
      }
    }
    handleFormulario('nome', '');
    handleEmpresa('nome', '');
    handleEmpresa('cnpj', formulario.cnpj);
  }

  const [show, setShow] = useState(false);

  const empresaExiste = (cnpj) =>  keycloak.tokenParsed && 
  empresasCPE && 
  empresasCPE.length !== 0 && 
  empresasCPE.find(e => e.cnpj === cnpj.trim())

  function handleAtualizar() {
    if(!formulario.cnpj ) return; //|| formulario.cnpj == empresa.cnpj
    const isCnpjValid = validarCNPJ(formulario.cnpj);
    if(isCnpjValid) {
      if(!empresaExiste(formulario.cnpj)) {
        setShow(true);
        return;
      }
      handleFormulario('isLoading', true);
      setBoletos([]);
      setError('');

      handleEmpresa('ano', formulario.ano);
      handleEmpresa('nome', '');
      handleEmpresa('cnpj', formulario.cnpj);
      fetchBoletos({cnpj: formulario.cnpj, ano: formulario.ano}, () => {
        setMaxBoletos(3);
        handleFormulario('isLoading', false);
      });
    } else {
      setError('CNPJ inválido')
    }
  }

  function VerMais() {
    return !!boletos.naoPagos && maxBoletos < boletos.naoPagos.length ?  
      <button
        type="button"
        className="sb-components__button inline radius-4 plus"
        data-sb-acao="clique"
        data-sb-rotulo="Ver Mais"
        onClick={() => setMaxBoletos(maxBoletos+3)}
      >Ver Mais
    </button>: <></>
  }

  return <>
    <div className="sb-area-logada__das__item active">
      <div className="row align-items-center">
        <div className="col-md-5">
          <div className="sb-area-logada__das__text">
            <button 
              type="button"
              className="sb-area-logada__das__voltar"
              onClick={() => back(true)}
              onKeyPress={() => back(true)}
            >Voltar
            </button>
            <h3 className="sb-das-h32">Histórico de pagamentos</h3>
            <FormularioDAS 
              handleFormulario={handleFormulario} 
              cnpjError={error}
              shouldDisableCnpj={shouldDisableCnpj}
              setShouldDisableCnpj={setShouldDisableCnpj}
              Button={
              <button
                type="button"
                className="sb-components__button inline radius-4"
                data-sb-acao="entrada-de-dados - boleto"
                data-sb-rotulo={`Atualizar - ${formulario.ano}`}
                onClick={() => {handleAtualizar(); setShouldDisableCnpj(true)}}
                disabled={atualizarBloqueadoRN}>
                Atualizar
              </button>}
            />
          </div>
        </div>
        <div className="col-md-7">
          <div className="sb-area-logada__das__table">
            <div className="sb-area-logada__das__table__head">
              <div className="sb-area-logada__das__table__head__flex">
                <p><strong>CNPJ:</strong> {empresa.cnpj}</p>
                <p><strong>Nome:</strong> {empresa.nome}</p>
                <p><strong>Ano:</strong> {empresa.ano}</p>
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th><input type="checkbox" name="" id="boletos-das-checkbox-all" onClick={handleCheckAll}/></th>
                    <th>Mês</th>
                    <th>Valor</th>
                    <th>Situação</th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="sb-area-logada__das__table__body">
              <table className="table">
                <tbody>
                  {!!boletos.naoPagos && !!boletos.naoPagos.length 
                  ? <BoletosNaoPago 
                    boletos={boletos.naoPagos}
                    maxBoletos={maxBoletos}
                    handleCheck={handleCheck}/>
                  : !!boletos.pagos && !!boletos.pagos.length
                    ?
                    <tr>
                      <td colSpan={4}>
                        <div className="sb-utilities__text-center" style={{width: "80px", height:"80px"}}></div>
                      </td>
                    </tr>
                    :
                    <tr>
                      <td colSpan={4}>
                        <div className="sb-utilities__text-center">
                          <img width="80px" src={`${state.env.REACT_APP_WEM_URL}/Sebrae/Portal Sebrae/resources/images/sb-loading-default.gif`}/>
                        </div>
                      </td>
                    </tr>
                }
                </tbody>
              </table>
              <VerMais />
              <div className="sb-area-logada__das__table__accordion">
                <ul>
                    {boletos.pagos &&
                    <li>
                      <button type="button">Ver boletos pagos</button>
                      <div className="sb-area-logada__das__table__accordion__content">
                        <BoletosPago boletos={boletos.pagos} />
                      </div>
                    </li>
                    }
                </ul>
              </div>
            </div>
            <div className="sb-area-logada__das__table__foot">
              <div className="sb-utilities__text-right">
                <button
                  type="button"
                  className="sb-components__button inline radius-4"
                  onClick={() => next()}
                  onKeyPress={() => next()}
                  data-sb-acao='clique'
                  data-sb-rotulo={`Emitir boletos - ${formulario.ano}`}
                  disabled={emitirBoletosBloqueadoRN}
                >Emitir Boletos
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {show ? <ModalCadastroPJ empresasCPE={empresasCPE} perfil show={show} onChangeShow={setShow} cnpj={formulario.cnpj}/> : <></> }

  </>
}
