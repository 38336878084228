import React from "react";
import PropTypes from "prop-types";


function HeaderModal({
  modalState,
  messagePJ = '',
  mensagemErroCnpj = '',
  cnpjShowing,
  cnpjInputRef,
  cadastroEmpresaRef,
  adicionarEmpresaRef,
  pjErrorRef,
  adicionarEmpresa = () => {},
  handleCnpj = () =>{}
}) {
  function handleAdicionarEmpresa(event) {
    event.preventDefault();
    adicionarEmpresa()
  }

  return (
    <>
      <p>{messagePJ}</p>
      <div
        className="sb-components__form__ipt-group"
        id="cnpjInput"
        ref={cnpjInputRef}
      >
        <label>CNPJ (apenas números)</label>
        <input
          type="text"
          id="cadastroEmpresa"
          maxLength="18"
          disabled={modalState === 'ADDING'}
          value={cnpjShowing}
          ref={cadastroEmpresaRef}
          onChange={(e) => {
            handleCnpj(e);
          }}
        />

        <span className="set-error" id="pjError" ref={pjErrorRef} hidden>
          {mensagemErroCnpj}
        </span>
        <span
          onClick={handleAdicionarEmpresa}
          onKeyPress={handleAdicionarEmpresa}
          className={`sb-components__button inline radius-4 plus ${modalState === 'ADDING'? 'disabled' : ''}`}
          data-sb-acao="clique"
          data-sb-rotulo="Adicionar"
          id="adicionarEmpresa"
          ref={adicionarEmpresaRef}
        >
          Adicionar
        </span>
      </div>
      <a
        href="#"
        data-sb-acao="clique"
        data-sb-rotulo="Por que pedimos esse dado?"
        id="porque-pedimos-esse-dado"
      >
        Por que pedimos esse dado?
        <br />
        Com o vínculo das empresas, podemos te ajudar com uma série de
        serviços adequados a sua realidade.
      </a>
    </>
  );
}

HeaderModal.protoTypes = {
  messagePJ: PropTypes.string,
  mensagemErroCnpj: PropTypes.string,
  cnpjShowing: PropTypes.bool,
  cnpjInputRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  cadastroEmpresaRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  adicionarEmpresaRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  pjErrorRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  adicionarEmpresa: PropTypes.func,
  handleCnpj: PropTypes.func
};

export default HeaderModal;