import React from 'react'
import {Redirect, Route, Router, Switch} from 'react-router-dom'
import {createBrowserHistory} from 'history'
import {SnackbarProvider} from 'notistack'
import PropTypes from 'prop-types'
import {AppContextProvider} from './components/shared/context'
import './main.css'
import {Provider} from 'react-redux'
import store from './store'
import NotFound from './pages/notfound'
import Main from './pages/home'
import MinhasInscricoes from './pages/minhas-inscricoes'
import DownloadSbrtPdf from './pages/sbrt/'

const hist = createBrowserHistory()

function App(props) {
  const {keycloak, env} = props

  return (
    <Provider store={store}>
      <div>
        <AppContextProvider keycloak={keycloak} env={env}>
          <SnackbarProvider maxSnack={1}>
            <Router history={hist}>
              <Switch>
                <Route exact path={'/'} component={Main}/>
                <Route exact path={'/minhasinscricoes'} component={MinhasInscricoes}/>
                <Route path={'/downloadSbrtPdf/:id'} component={DownloadSbrtPdf}/>
                <Route exact path={'/404'} component={NotFound}/>
                <Redirect to={'/404'}/>
              </Switch>
            </Router>
          </SnackbarProvider>
        </AppContextProvider>
      </div>
    </Provider>
  )
}

export default App

App.propTypes = {
  keycloak: PropTypes.shape({}).isRequired,
}
