import React from "react";

export default function WhatsappWidget() {
  return (
    <div className="sb-fale-conosco__chat mini sbAccessibilityFontSize">
      <div className="sb-fale-conosco__chat__box sbAccessibilityFontSize"></div>
      <a
        style={{backgroundImage: "url(/images/icons/portal/sb-icon__whatsapp.svg)"}}
        className="sb-fale-conosco__whats"
        href=" https://api.whatsapp.com/send?phone=5508005700800"
        target="_blank"
        aria-label="enviar uma mensagem"
      ></a>
      <a className="sb-fale-conosco__close"></a>
    </div>
  );
}
