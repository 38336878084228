import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useGlobalState} from '../../shared/context/AppContext'

import useBoletos from './hooks/useBoletos'
import useFormulario from './hooks/useFormulario'
import useAtendimento from './hooks/useAtendimento'
import useStep from './hooks/useStep'

import PreListagemBoletos from './PreListagemBoletos'
import ListagemBoletos from './ListagemBoletos'
import ListagemBoletosEmitidos from './ListagemBoletosEmitidos'
import axios from 'axios'
import ModuloDasContext from './ModuloDasContext'


export default function ModuloDas({keycloak, empresasCPE}) {
  const [state] = useGlobalState();
  const estados = useSelector(state => state.estados);
  const url = useSelector(state => state.url);
  const dispatch = useDispatch();

  const {step, next, back} = useStep();
  const [boletos, setBoletos, fetchBoletos, checkedBoletos, setCheckedBoletos] = useBoletos({url:url})
  const [isMEI, setIsMEI] = useState({ runOne: false, is: false, data:{} });
  const [ {empresa, setEmpresa, handleEmpresa, flushEmpresa}, {formulario, setFormulario, handleFormulario}
  ] = useFormulario();

  const [sendAtendimento] = useAtendimento({
    keycloak:keycloak, endpoint:state.env.BACKEND_URL+"/v1/atendimento/gerarAtendimentoDAS"
  });

  useEffect(() => {
    dispatch({type: 'ADD_URL', data: state.env.BACKEND_URL})
  })

  useEffect(() => restart(), [keycloak.tokenParsed, empresasCPE, estados])

  useEffect(() => {
    if(empresa.cnpj && empresasCPE && empresasCPE.length) {
      if(isMEI && !isMEI.runOne) {
        axios.get(`${state.env.BACKEND_URL}/v1/cpe/pj/${empresa.cnpj}`)
          .then(data => {
          if(data.status === 200 && !!data.data && data.data['porte'] == '99') {
            console.debug("IS",data.data);
            setIsMEI(prev => {return {...prev, runOne: true, is: true, data:{...data.data} }})
          }
          else {
            console.debug("NOT",data.data);
            setIsMEI(prev => {return {...prev, runOne: true, is: false, data:{...data.data} }})
          }
        })
        .catch(err => {
          console.trace(`Erro ao requisitar cnpj: ${empresa.cnpj}`,err);
          setIsMEI(prev => {return {...prev, runOne: true, is: false}})
        });
      }
    }
  }, [empresa])

  function restart() {
    setBoletos([]);
    setCheckedBoletos([]);
    const principal = getEmpresaPrincipal(empresasCPE);
    const estado = getEstado(keycloak.tokenParsed.uf);
    const ano = new Date().getFullYear();
    const obj = localStorage.getItem('DAS_CNPJ_CADASTRADO')? {
      ...principal,
      estado,
      ano,
      cnpj: localStorage.getItem('DAS_CNPJ_CADASTRADO')
    } : {
      ...principal,
      estado,
      ano
    }
    setEmpresa(obj);
    setFormulario(obj);
  }

  function getEstado(params) {
    if (!!params) return null;
    const estado = estados.filter(estado => estado.min === params)[0];
    if(estados && estado){
      return estado['cod']
    }
    return estados;
  }

  function getEmpresaPrincipal(empresas) {
    if (empresas === null || typeof(empresas) === "undefined") return null
    return empresas.filter((empresa) => empresa.isPrincipal === true)[0]
  }

  return (
    (keycloak.tokenParsed && (!empresasCPE || empresasCPE.length == 0) ) || 
    (!!empresa.cnpj && isMEI.is) ? 
    <>
      <ModuloDasContext.Provider value={{ formulario, empresa, boletos, checkedBoletos, step, sendAtendimento }}>
      <section className="sb-area-logada__das" data-sb-categoria="emissor-boleto-das-dasn - /sites/PortalSebrae;">
      {{
        1:<PreListagemBoletos
            flushEmpresa={flushEmpresa}
            next={next}
            handleFormulario={handleFormulario}
            isMEI={isMEI.is}
            keycloak={keycloak}
            empresasCPE={empresasCPE}
          />,
        2:<ListagemBoletos
            handleFormulario={handleFormulario}
            handleEmpresa={handleEmpresa}
            setBoletos={setBoletos}
            flushEmpresa={flushEmpresa}
            next={next}
            back={(toTheBeginning) => {restart(); back(toTheBeginning)}}
            fetchBoletos={fetchBoletos}
            setCheckedBoletos={setCheckedBoletos}
            keycloak={keycloak}
            empresasCPE={empresasCPE}
          />,
        3:<ListagemBoletosEmitidos
            back={(toTheBeginning) => {back(toTheBeginning)}}
            handleFormulario={handleFormulario}
          />
      }[step]}
    </section>
    </ModuloDasContext.Provider>
  </>
  : <></>
  );
}
