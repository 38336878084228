import React from "react";
import PropTypes from "prop-types";

function ErrorModal({ message, closeModal, handleAdicionarOutroCnpj }) {
  return (
      <>
        <div className="sb-components__alert error">
          <strong>Erro!</strong>
          <p>{message}</p>
        </div>
        <div className="sb-components__form__buttons">
          <a
            href="#modal-vinculo-tag"
            className="sb-components__button inline radius-4 small-btn"
            onClick={closeModal}
            data-sb-acao="clique"
            data-sb-rotulo="Fechar"
          >
            Fechar
          </a>
          <a
            className="sb-components__button outline radius-4 large-btn"
            onClick={handleAdicionarOutroCnpj}
            data-sb-acao="clique"
            data-sb-rotulo="Adicionar outro CNPJ"
          >
            Adicionar outro CNPJ
          </a>
        </div>
      </>
  );
}

ErrorModal.protoTypes = {
  message: PropTypes.string,
  closeModal: PropTypes.func,
  handleAdicionarOutroCnpj: PropTypes.func,
};

export default ErrorModal;
