import axios from "axios";

export default function useAtendimento({keycloak, endpoint}) {

  function sendAtendimento({
    guid,
    tipo,   
    tema,
    titulo,
    codTema,
    resumo,
    codInstrumento,
    retranca,
    contabilizacaoPNEE,
    indEmailMkt,
    cpf,
    cnpj,
    url,
    codAtendimento,
    indEmissor,
    dtInicio,
    cnpj_das,
    dt_competencia_das
  }, callback=(data)=>{}) {
    const config = {
      headers: { Authorization: `Bearer ${keycloak.token}`}
    };
    const body={
      guid,
      tipo,   
      tema,
      titulo,
      codTema,
      resumo,
      codInstrumento,
      retranca,
      contabilizacaoPNEE,
      indEmailMkt,
      cpf,
      cnpj,
      url,
      codAtendimento,
      indEmissor,
      dtInicio,
      cnpj_das,
      dt_competencia_das
    };
    axios.post(endpoint, body, config)
    .then(data => callback(data))
    .catch(err => console.log(err));
  }

  return [sendAtendimento];
}