import React, {useEffect} from 'react'
import {useGlobalState} from '../context/AppContext'
import axios from 'axios'
import $ from 'jquery'
import {getCacheDate} from '../../../date';

const Header = (props) => {
  const keycloak = props.keycloak.tokenParsed

  const [state, dispatch] = useGlobalState()

  useEffect(() => {
    let url = state.env.BACKEND_URL
    axios.get(`${url}/v1/header`)
      .then(res => {
        document.getElementById('header').innerHTML = res.data
        window.urlAmbiente = state.env['REACT_APP_WEM_URL']
        axios.get('/keycloak.json?v='+getCacheDate()).then(config => {
          $.getScript(config.data.url+'/js/keycloak.js', function(){
            $.getScript(window.urlAmbiente + "/Sebrae/Portal Sebrae/resources/js/amei/keycloak-helper.js?version=" + getCacheDate(), function(){
            $.getScript(window.urlAmbiente + "/Sebrae/Portal Sebrae/resources/js/qrcode/qrcode.min.js")  
			$.getScript(window.urlAmbiente + "/Sebrae/Portal Sebrae/resources/js/html/header.js")
              if(state.env.REACT_APP_WEM_URL && state.env.REACT_APP_WEM_URL.includes("conteudoh")) {
                $.getScript("https://chatbot-webchat.homolog.sebrae.com.br/web-component/webchat-embed.js")
              } else {
                $.getScript("https://chatbot-webchat.sebrae.com.br/web-component/webchat-embed.js")
              }
            })
          })
        })
        let isRendered = state.isRendered
        isRendered['header'] = true
        dispatch({isRendered: isRendered})
        importVLibras()
		manipulaCpfNoDataLayer()
      })
      .catch(err => {
        console.error('ocorreu um erro ' + err)
      })
  }, [])


function importVLibras (){
  const script = document.createElement('script')
  script.src = "https://vlibras.gov.br/app/vlibras-plugin.js"
  script.async = true
  script.onload = () => {
    new window.VLibras.Widget('https://vlibras.gov.br/app')
    window.onload()
  }
  document.head.appendChild(script);
}

function manipulaCpfNoDataLayer() {
	window.dataLayer = window.dataLayer || [];
	if (typeof window !== "undefined") {
		window.dataLayer.push({
            cpf: keycloak.cpf
        })
        window.dataLayer.push({
            event: "set_user_id",
            user_id: keycloak.sub
        })
	}
}


  return (
    <>
      <div vw="true" className="enabled">
        <div vw-access-button="true" className="active"></div>
        <div vw-plugin-wrapper="true">
            <div className="vw-plugin-top-wrapper"></div>
        </div>
        </div>
    </>
  )
}

export default Header
