import React from "react";
import PropTypes from "prop-types";

/**
 * 
 * @param empresasTable    
 * @param empresasCadastradas    
 * @param cadastrarEmpresas    
 * @param finishCadastroPJ    
 * @param cancelarCadastroPJ    
 * @returns 
 */
function FooterModal({
  empresasTable = [],
  empresasCadastradas = false,
  cadastrarEmpresas = () => {},
  finishCadastroPJ = () => {},
  cancelarCadastroPJ = () => {}
}) {
  function handleFinishCadastroPJ(event) {
    event.preventDefault();
    finishCadastroPJ();
  }

  function handleCancelarCadastroPJ(event) {
    event.preventDefault();
    cancelarCadastroPJ();
  }

  return (
    <div className="sb-components__form__buttons">
      {empresasTable.length === 0 ? (
        <span
          className="sb-components__button inline radius-4 disabled"
          id="cadastrarEmpresa"
        >
          Cadastrar
        </span>
      ) : (
        <span
          data-sb-acao="clique"
          data-sb-rotulo="Concluir"
          onClick={cadastrarEmpresas}
          onKeyPress={cadastrarEmpresas}
          className="sb-components__button inline radius-4"
          id="cadastrarEmpresa"
        >
          Concluir
        </span>
      )}
      {empresasCadastradas ? (
        <span
          data-sb-acao="clique"
          data-sb-rotulo="Cancelar"
          onClick={handleFinishCadastroPJ}
          onKeyPress={handleFinishCadastroPJ}
          className="sb-components__button outline radius-4"
        >
          Cancelar
        </span>
      ) : (
        <span
          data-sb-acao="clique"
          data-sb-rotulo="Cancelar"
          onClick={handleCancelarCadastroPJ}
          onKeyPress={handleCancelarCadastroPJ}
          className="sb-components__button outline radius-4"
        >
          Cancelar
        </span>
      )}
    </div>
  );
}

FooterModal.protoTypes = {
  empresasTable: PropTypes.array,
  empresasCadastradas: PropTypes.bool,
  cadastrarEmpresas: PropTypes.func,
  finishCadastroPJ: PropTypes.func,
  cancelarCadastroPJ: PropTypes.func
};

export default FooterModal;