import {getCacheDate} from '../../src/date';
const axios = require('axios')

export const getUrlApi = async (variavel) => {
  const resp = await axios.get('/env.json?v=' + getCacheDate())
  return resp.data[variavel]
}

// Interceptios register
axios.interceptors.response.use((response) => {
  //setLoading(false);

  return response
}, (error) => {
  if (error.response != null && (error.response.status === 401 || error.response.status === 403)) {
    window.location = '/'
  } else {
    return Promise.reject(error)
  }
})

axios.interceptors.request.use((config) => {
  //setLoading(true)
  return config
}, (error) => Promise.reject(error))
