import React, { useContext, useState } from 'react'
import { useGlobalState } from '../../../shared/context/AppContext';
import ModalCadastroPJ from '../../../shared/modalCadastroPJ';
import FormularioDAS from '../FormularioDAS/FormularioDAS'
import ModuloDasContext from '../ModuloDasContext';
import { validarCNPJ } from '../utils/cnpjHelper'
import LazyLoad from 'react-lazyload';

export default function PreListagemBoletos({
  handleFormulario,
  flushEmpresa,
  next,
  isMEI,
  keycloak,
  empresasCPE
}) {
  const [state] = useGlobalState();
  const {formulario} = useContext(ModuloDasContext);
  const [error, setError] = useState('');
  const [show, setShow] = useState(false);

  const [shouldDisableCnpj, setShouldDisableCnpj] = useState(true);

  const empresaExiste = (cnpj) =>  keycloak.tokenParsed && 
  empresasCPE && 
  empresasCPE.length !== 0 && 
  empresasCPE.find(e => e.cnpj === cnpj.trim())

  function handleInteraction() {
    if(!formulario.cnpj) return;
    const isCnpjValid = validarCNPJ(formulario.cnpj);
    if(isCnpjValid) {
      if(!empresaExiste(formulario.cnpj)) {
        setShow(true);
        return;
      }
      setError('');
      flushEmpresa();
      next();
    } else {
      setError('CNPJ inválido');
    }
  }


  return <>
    <div className="sb-area-logada__das__item active">
      <div className="row align-items-center">
        <div className="col-md-6">
          <div className="sb-area-logada__das__text">
            <h3>{isMEI ? 'Emita seu boleto MEI aqui!' : 'Emita seu boleto MEI aqui!' }</h3>
            <p>{isMEI ? 
              'É simples! Informe seu CNPJ MEI e baixe mensalmente o boleto/imposto DAS para pagamento junto ao banco.' 
              : 
              'É simples! Informe seu CNPJ MEI e baixe mensalmente o boleto/imposto DAS para pagamento junto ao banco.' }
            </p>
            <div className="sb-area-logada__das__form">
              <FormularioDAS 
                handleFormulario={handleFormulario} 
                cnpjError={error}
                shouldDisableCnpj={shouldDisableCnpj}
                setShouldDisableCnpj={setShouldDisableCnpj}
              />
            </div>
            <button
              type="button"
              className="sb-components__button inline radius-4"
              data-sb-acao="entrada-de-dados - boleto"
              data-sb-rotulo={`${isMEI ? 'Emitir boleto DAS':'Emitir boleto DAS'} - ${formulario.ano}`}
              onClick={() => handleInteraction()}
              onKeyPress={() => handleInteraction()}
            >{isMEI ? 'Emitir boleto DAS':'Emitir boleto DAS'}
            </button>
          </div>
        </div>
        <div className="col-md-6">
        <LazyLoad>
          <picture>
            <source srcSet={`${state.env.REACT_APP_WEM_URL}/Sebrae/Portal%20Sebrae/resources/images/area-logada/das/sb-img-das-webp.webp`} type="image/webp" /> 
            <img src={`${state.env.REACT_APP_WEM_URL}/Sebrae/Portal Sebrae/resources/images/area-logada/das/sb-img-das.png`} alt="" className="sb-image-destaqe"/>
          </picture>
        </LazyLoad>
        </div>
      </div>
    </div>
    {show ? <ModalCadastroPJ empresasCPE={empresasCPE} perfil show={show} onChangeShow={setShow} cnpj={formulario.cnpj}/> : <></> }
  </>
}