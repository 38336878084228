import React, {useEffect, useState} from 'react'
import $ from 'jquery'
import axios from 'axios'
import {useGlobalState} from '../context/AppContext'
import { useCpe } from '../modalCadastroPJ/useCpe'

export default function ModalDesvincularPJ() {
  const [state] = useGlobalState()

  const [cnpjDesvincular, setCnpjDesvincular] = useState('')
  const {deletePjVinculo} = useCpe()

  useEffect(() => {
    document.addEventListener('desvincular-pj', e => desvincularPj(e.detail))
  }, [])

  function desvincularPj(cnpj) {
    setCnpjDesvincular(cnpj)
    $('#modal-desvincular-pj').fadeIn('fast').addClass('active')
  }

  function cancelarDesvincularPj() {
    $('#modal-desvincular-pj').fadeOut('fast').removeClass('active')
  }

  async function confirmarDesvincularPj() {
    deletePjVinculo(cnpjDesvincular, () => window.location.reload())
  }

  return <div className="sb-modal sb-modal-dialog" id="modal-desvincular-pj">
    <div className="sb-modal__wrapper">
      <div className="sb-modal-content">
        <div className="sb-modal-header">
          <strong>Desvincular empresa</strong>
          <span className="sb-modal-close" onClick={cancelarDesvincularPj} onKeyPress={cancelarDesvincularPj}/>
        </div>
        <div className="sb-modal-body">
          <p>Tem certeza que deseja desvincular essa empresa do seu cadastro?</p>
          <div>
            <a onClick={cancelarDesvincularPj} onKeyPress={cancelarDesvincularPj}
               className="sb-components-button inline small radius-5 text-center">Não desejo desvincular</a>
            <a onClick={confirmarDesvincularPj} onKeyPress={confirmarDesvincularPj}
               className="sb-components-button outline small radius-5 text-center">Sim, desvincular</a>
          </div>
        </div>
      </div>
    </div>
  </div>
}
