import React, {useContext, useEffect, useState} from 'react'

import Footer from '../../components/shared/footer'
import Header from '../../components/shared/header'
import Axios from 'axios'

import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import {Context, useGlobalState} from '../../components/shared/context/AppContext'
import getMainJs from '../../services/Api_mainjs'

export default function MinhasInscricoes() {
  const params = {
    endpoints: {
      banner: '/v1/minhas-inscricoes/banner',
      carrosselCursosOnline: '/v1/minhas-inscricoes/carrosselCursosOnline',
      cursosInscritos: '/v1/minhas-inscricoes/cursosEad',
    },
    pageSize: 4,
  }

  const {keycloak} = useContext(Context)
  const [state] = useGlobalState()
  const [isMobile] = useState(window.innerWidth < 992)
  const [banner, setBanner] = useState({})
  const [carrosselCursosOnline, setCarrosselCursosOnline] = useState({})
  const [cursosOnline, setCursosOnline] = useState([])
  const [hasMoreCursosOnline, setHasMoreCursosOnline] = useState(false)
  const [loading, setLoading] = useState(true)
  const [urlBackend, setUrlBackend] = useState(false)
  const [urlWem, setUrlWem] = useState(false)
  const [urlEad, setUrlEad] = useState(false)
  const [favorites, setFavorites] = useState([])
  const usuarioSub = keycloak.tokenParsed.sub

  useEffect(() => {
    if (isMobile) params.pageSize = 0
    setUrlWem(state.env.REACT_APP_WEM_URL)
    setUrlBackend(state.env.BACKEND_URL)
    setUrlEad(state.env.EAD_URL)

    getMainJs(state.env.REACT_APP_WEM_URL)

    getBanner(state.env.BACKEND_URL)
    getCarrosselCursosOnline(state.env.BACKEND_URL)
    getCursos(state.env.BACKEND_URL)
    getFavorites(state.env.BACKEND_URL)
  }, [])

  function getFavorites(baseUrl) {
    Axios.get(`${baseUrl}/v1/favoritos/${usuarioSub}`)
      .then(res => {
        setFavorites(res.data)
      })
  }

  function getBanner(baseUrl) {
    Axios.get(baseUrl + params.endpoints.banner)
      .then(response => {
        setBanner(response && response.data || {})
        document.body.style.setProperty('cursor', 'unset')
        document.body.style.setProperty('opacity', '1', 'important')
      })
  }

  function getCarrosselCursosOnline(baseUrl) {
    Axios.get(baseUrl + params.endpoints.carrosselCursosOnline)
      .then(response => {
        setCarrosselCursosOnline(response && response.data || {})
      })
  }

  function getCursos(baseUrl) {
    setLoading(true)

    Axios.get(`${baseUrl}${params.endpoints.cursosInscritos}?page=${getNextPage()}&pageSize=${params.pageSize}`)
      .then(response => {
        const data = response && response.data
        setCursosOnline([...cursosOnline, ...(data && data.cursosOnline || [])])
        setHasMoreCursosOnline(data && data.hasMoreCursosOnline || false)
        setLoading(false)
      })
  }

  function getNextPage() {
    return ~~(cursosOnline.length / params.pageSize)
  }

  //se ja for favorito, deve desfavoritar
  function favoritar(baseUrl, conteudoGuid) {
    if (favorites.includes(conteudoGuid)) {
      Axios.delete(`${baseUrl}/v1/favoritos/${usuarioSub}/${conteudoGuid}`)
        .then(() => {
          document.getElementById(conteudoGuid).classList.remove('active')
        })
    } else {
      Axios.post(`${baseUrl}/v1/favoritos`, {conteudoGuid, usuarioSub})
        .then(() => {
          document.getElementById(conteudoGuid).classList.add('active')
        })
    }
  }

  function getCarrossels() {
    if (cursosOnline.length === 0) {
      if (loading) {
        return (<div className="sb-loading"><span/></div>)
      }

      return (
        <div className="sb-amei-my-inscription__not-found">
          <div className="sb-amei-my-inscription__not-found__logo">
            <span/>
          </div>
          <div className="sb-amei-my-inscription__not-found__title">
            <p>Você ainda não possui nenhuma inscrição.</p>
          </div>
          <div className="sb-amei-my-inscription__not-found__subtitle">
            <p>
              <a href={`${urlWem}/sites/PortalSebrae/cursosonline`}>
                Clique aqui
              </a> e confira a vitrine de cursos online do Sebrae.
            </p>
          </div>
        </div>
      )
    }

    return (
      <section className="sb-area-logada__cards sb-utilities__bg__light-gray">
        <div className="sb-grid__container">
          <div className="sb-area-logada__title-h1">{carrosselCursosOnline.titulo}</div>
          <div className="sb-components__carousel overflow-x-inherit-md">
            <div className="owl-carousel owl-theme">
              {
                cursosOnline.map((elem, index) => {
                  const item = elem.content
                  return (
                    <div className="item" key={index}>
                      <div className={`sb-components__card card-curso online ${item.classeTema}`}>
                        <div className="sb-components__card__img"
                             style={{backgroundImage: item.imagem ? `url('${urlWem}${item.imagem}'` : null}}>
                          <div className="sb-components__favorite">
                            <div
                              className={`sb-components__favorite__content ${favorites.includes(item.id) ? 'active' : ''}`}
                              id={item.id}>
                              <div className="sb-components__favorite__content__text">
                                Salvo na lista de favoritos
                              </div>
                              <span className="sb-components__favorite__content__icon" data-sb-acao="favoritos" data-sb-rotulo="${item.id}"
                                    onClick={() => favoritar(urlBackend, item.id)}
                                    title="Salvar na lista de favoritos"/>
                            </div>
                          </div>
                        </div>

                        <div className="sb-components__card__info">
                          <div className="sb-components__card__info__tags">
                            <span>{item.courseType}</span> /
                            <span className="sb-components__card__info__tags__theme">&nbsp;{item.tema}</span>
                          </div>

                          <div className="sb-components__card__info__title">
                            <a href={urlWem + item.contentLink} title={item.titulo}>
                              {item.titulo}
                            </a>
                          </div>

                          <div className="sb-components__card__info__details">
                            <span className="sb-components__card__info__details__type free">Gratuito</span>
                            {item.cargaHoraria && item.cargaHoraria > 0
                              ? <span className="sb-components__card__info__details__icon ic1">
                              Duração {Number(item.cargaHoraria)}h
                            </span>
                              : <></>
                            }
                            {item.observacao
                              ? <span className="sb-components__card__info__details__icon ic2">
                              Conclusão em {item.observacao}
                            </span>
                              : <></>
                            }
                          </div>
                          <a
                            href={elem.enrolled ? item.urlEad : `${urlEad}/Portal/Modules/HistorySubjects/`}
                            className="sb-components__button inline radius-4">
                            {
                              elem.enrolled ? 'Continue' : 'Acesse'
                            }
                          </a>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
          {
            hasMoreCursosOnline
              ?
              <div className="sb-utilities__text-center">
                {
                  loading
                    ? <div className="sb-loading"><span/></div>
                    : <a className="sb-components__button inline radius-4" onClick={() => getCursos(urlBackend)}>
                      Carregar mais
                    </a>
                }
              </div>
              :
              <></>
          }
        </div>
      </section>
    )
  }

  return (
    <div>
      <Header keycloak={keycloak}/>
      <div className="sb-amei-my-inscription">
        <section className="sb-common-content__banner-amei"
                 style={{backgroundImage: `url("${banner.imagemFundo}"`}}>
          <div className="sb-common-content__banner-amei__title">
            <p>{banner.titulo}</p>
          </div>
        </section>

        <div className="migalha-amei">
          <div id="breadcrumb-webinar">
            <div className="sb-components-breadcrumb">
              <ul>
                <li><a href={`${urlWem}/sites/PortalSebrae`}>Página inicial</a></li>
                <li><a href="/">Meu mural</a></li>
                <li>Minhas inscrições</li>
              </ul>
            </div>
          </div>
        </div>

        {getCarrossels()}
      </div>
      <Footer/>
    </div>
  )
}
