import React, { useEffect } from 'react'
import './index.css'
import getUrlFooter from '../../../services/Api_footer'
import axios from 'axios'

import { useGlobalState} from '../../../components/shared/context/AppContext'

const Footer = () => {
  const [state] = useGlobalState()

  useEffect(() =>{
    axios.get(`${state.env.BACKEND_URL}/v1/footer`)
      .then(res => {
        document.getElementById('footer').innerHTML = res.data
      }).catch(err => {
      console.log('ocorreu um erro ' + err)
    })
  } ,[])


  return (
    <div>
      {/* {getUrlFooter()} */}
    </div>
  )
}

export default Footer
