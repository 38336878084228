const INITIAL_STATE = {
  url: '',
  estados: [
    {cod: 1, min: 'AC', name: 'Acre'},
    {cod: 2, min: 'AL', name: 'Alagoas'},
    {cod: 3, min: 'AP', name: 'Amapá'},
    {cod: 4, min: 'AM', name: 'Amazonas'},
    {cod: 5, min: 'BA', name: 'Bahia'},
    {cod: 6, min: 'CE', name: 'Ceará'},
    {cod: 7, min: 'DF', name: 'Distrito Federal'},
    {cod: 8, min: 'ES', name: 'Espirito Santo'},
    {cod: 10, min: 'GO', name: 'Goiás'},
    {cod: 11, min: 'MA', name: 'Maranhão'},
    {cod: 14, min: 'MG', name: 'Minas Gerais'},
    {cod: 12, min: 'MT', name: 'Mato Grosso'},
    {cod: 13, min: 'MS', name: 'Mato Grosso do Sul'},
    {cod: 15, min: 'PA', name: 'Pará'},
    {cod: 16, min: 'PB', name: 'Paraíba'},
    {cod: 17, min: 'PR', name: 'Paraná'},
    {cod: 18, min: 'PE', name: 'Pernambuco'},
    {cod: 19, min: 'PI', name: 'Piauí'},
    {cod: 20, min: 'RJ', name: 'Rio de Janeiro'},
    {cod: 21, min: 'RN', name: 'Rio Grande do Norte'},
    {cod: 22, min: 'RS', name: 'Rio Grande do Sul'},
    {cod: 23, min: 'RO', name: 'Rondônia'},
    {cod: 9, min: 'RR', name: 'Roraima'},
    {cod: 25, min: 'SC', name: 'Santa Catarina'},
    {cod: 26, min: 'SP', name: 'São Paulo'},
    {cod: 27, min: 'SE', name: 'Sergipe'},
    {cod: 24, min: 'TO', name: 'Tocantins'},
  ],
}

function estados(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'ADD_ESTADO':
      return {...state, estados: [...state.estados, action.data]}
    case 'ADD_URL':
      return {...state, url: action.data}
    default:
      return state
  }
}

export default estados
