import React, { useState } from 'react'
import ModalCadastroPJ from '../modalCadastroPJ'
import ModalDesvincularPJ from '../modalDesvincularPJ'
import ModalIncentivo from '../incentivo'
import ModalVinculoEmpresa from '../modalVinculoEmpresa'
import { useCpe } from '../modalCadastroPJ/useCpe'

function ModalManager ({
  perfil,
  modalPJShow,
  setModalPJShow,
  perfilCallback,
  modalPerfil,
  setModalPerfil
}) {
  const [empresas, setEmpresas] = useState();
  const {getEmpresasVinculadas} = useCpe();

  useState(()=> {
    getEmpresasVinculadas((resp) => {
      setEmpresas(resp.data)
    });
  },[])
  return (
    <>
      <ModalCadastroPJ empresasCPE={empresas} perfil={perfil} show={modalPJShow} onChangeShow={setModalPJShow}/>
      <ModalDesvincularPJ/>
      <ModalIncentivo  callback={perfilCallback} show={modalPerfil} onChangeShow={setModalPerfil}/>
      <ModalVinculoEmpresa empresasCPE={empresas} setModalPJShow={setModalPJShow}/>
    </>
  )
}


export default ModalManager