import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import * as Keycloak from 'keycloak-js'
import axios from 'axios'
import * as serviceWorker from './serviceWorker'
import App from './App'
import {getCacheDate} from './date';

axios.get('/env.json')
  .then(res => {
    const env = res.data

    try{
      Sentry.init({dsn: env.sentry.dsn, release: env.sentry.release, environment: env.sentry.environment})
    }catch(err){
      console.error(err.name +": "+ err.message)
    }

    axios.get('/keycloak.json?v='+getCacheDate()).then(config => {

      const keycloak = new Keycloak(config.data);

      const icon = document.createElement('link')
      icon.rel = 'shortcut icon'
      icon.href = env.REACT_APP_WEM_URL + '/Sebrae/Portal%20Sebrae/resources/img/favicon.png'
      document.head.appendChild(icon)

      axios.get(env.REACT_APP_WEM_URL + '/Sebrae/Portal%20Sebrae/resources/css/sb-main.min.css?v=' + getCacheDate())
        .then(res => {
          if (res.data) {
            const css = document.createElement('style')
            css.innerText = res.data
            document.head.appendChild(css)

            // finish loading the app
            keycloak.init({onLoad: 'login-required'}).success((auth) => {
              keycloak.updateToken().success();

              if (!auth) {
                window.location.reload()
              } else {

                axios.defaults.headers.common.Authorization = `Bearer ${keycloak.token}`
              }

              ReactDOM.render(
                <App keycloak={keycloak} env={env}/>, document.getElementById('root'),
              )

              keycloak.onTokenExpired = () => {
                keycloak.updateToken(30).success(() => {
                  axios.defaults.headers.common.Authorization = `Bearer ${keycloak.token}`
                }).error(() => {
                  console.error('Failed to refresh token')
                  window.location.reload()
                })
              }
            }).error((e) => {
              console.error('Authenticated Failed', e)
            })
          }
        })
        .catch(err => {
          console.error('CSS LOAD ERROR', err)
        })

    })
  })
  .catch(err => {
    console.error('Initialization error', err)
  })

serviceWorker.register()
