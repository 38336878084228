import Axios from "axios";
import $ from "jquery";

var validaWebinar = function (state) {
  let env = state.env;
  $(".btn-webinar").each(function (i, elem) {
    let nomeGuid = $(elem).attr("data-guid");
    let guid = nomeGuid.split(",")[1];
    Axios.get(`${env.BACKEND_URL}/v1/conteudosParaVoce/verificaInscricao`, {
      params: { guid: guid },
    }).then((isInscrito) => {
      if (isInscrito.data == true) {
        Axios.get(`${env.BACKEND_URL}/v1/conteudosParaVoce/getLink`, {
          params: { guid: guid },
        }).then((link) => {
          $(elem).on("click", () => {
            window.location.href = env.REACT_APP_WEM_URL + link.data.link;
          });
          $(elem).attr("href", env.REACT_APP_WEM_URL + link.data.link);
          $('.title-webinar[data-guid="' + nomeGuid + '"]').attr(
            "href",
            env.REACT_APP_WEM_URL + link.data.link
          );
        });
      } else {
        $(elem).text("Inscreva-se");
        $(elem).on("click", () => {
          window.location.href = `${env.REACT_APP_WEM_URL}/sites/PortalSebrae/Webinars/inscricao?webinar=${nomeGuid}&submitSubscription=true`;
        });
        $(elem).attr(
          "href",
          `${env.REACT_APP_WEM_URL}/sites/PortalSebrae/Webinars/inscricao?webinar=${nomeGuid}&submitSubscription=true`
        );
        $('.title-webinar[data-guid="' + nomeGuid + '"]').attr(
          "href",
          `${env.REACT_APP_WEM_URL}/sites/PortalSebrae/Webinars/inscricao?webinar=${nomeGuid}`
        );
      }
    });
    $(".webinar").each(function (i, elem) {
      Axios.get(`${env.BACKEND_URL}/v1/conteudosParaVoce/verificaWebinarLive`, {
        params: { guid: $(elem).attr("data-guid") },
      }).then((res) => {
        if (res.data) {
          $(elem).show();
        }
      });
    });
  });
};

export default validaWebinar;
