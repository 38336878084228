import React from 'react'
import OwlCarousel from "react-owl-carousel";

export default function Banner(props) {
  return (

    <section className="sb-components__banner-rotative"
                    data-sb-categoria="PS-DV-BANNER-ROTATIVO"
    >
      <OwlCarousel
        className="owl-carousel owl-theme"
        smartSpeed={500}
        items={1}
        loop={true}
        nav={true}
        autoplay={true}
        autoplayTimeout={6e3}
        autoplayHoverPause={false}
        dangerouslySetInnerHTML={{__html: props.banners}}
      />
    </section>
    )
}
