import React, {memo, useContext, useState, useEffect} from 'react'
import InputMask from 'react-input-mask'
import ModuloDasContext from '../ModuloDasContext';

function FormularioDAS(props) {
  const {formulario, empresa, step} = useContext(ModuloDasContext);
  const cnpjBloqueadoRN = (!!empresa.cnpj && props.shouldDisableCnpj) || step === 3;

  useEffect(() => {
    if(localStorage.getItem('DAS_CNPJ_CADASTRADO')) {
      props.handleFormulario('cpnj', localStorage.getItem('DAS_CNPJ_CADASTRADO'));
      localStorage.removeItem('DAS_CNPJ_CADASTRADO');
    }
  }, [])
  

  function CNPJ() {
    return (
      <div className="sb-components-form__ipt">
        <label htmlFor="sbCnpj">CNPJ</label>
        <InputMask
          name="cnpj"
          id="sbCnpj"
          className={!!props.cnpjError? "sb-form-error__alert" : ''}
          mask="99.999.999/9999-99"
          placeholder={!!empresa.cnpj ? "XX.XXX.XXX/XXXX-XX" : "Digite o CNPJ da sua empresa"}
          type="text"
          value={formulario.cnpj}
          disabled={cnpjBloqueadoRN}
          onChange={e => props.handleFormulario(e.target.name, e.target.value) }
        />
        {(!!empresa.cnpj && step !== 3) &&
        <a 
          onClick={() => props.setShouldDisableCnpj(false)}
          onKeyPress={() => props.setShouldDisableCnpj(false)} 
          id="new-cnpj"   
        >Inserir outro CNPJ
        </a>}
        {!!props.cnpjError &&
          <span className="sb-form-error__alert">{props.cnpjError}</span>
        }
      </div>)
  }

  function Ano() {
    const years = [
      new Date().getFullYear(),
      new Date().getFullYear() - 1,
      new Date().getFullYear() - 2,
      new Date().getFullYear() - 3,
      new Date().getFullYear() - 4
    ];
    return(
    <div className="sb-components-form__select">
      <label>Ano</label>
      <select 
        name="ano" 
        disabled={step === 3}
        data-sb-acao="entrada-de-dados - boleto"
        data-sb-rotulo={`Ano - ${formulario.ano}`}
        defaultValue={formulario.ano} 
        onChange={e => props.handleFormulario(e.target.name, parseInt(e.target.value))}>
      {years.map(year => 
          <option 
            key={year}
            defaultValue={year === formulario.ano} 
            value={year}>
            {year}
          </option>)
      }
      </select>
    </div>)
  }

  return (
    <>
    {step === 1? 
    <div className="sb-area-logada__das__form">
      {CNPJ()}
      {Ano()}
    </div>
    :
    <div className="sb-area-logada__das__form__flex">
      <div className="sb-area-logada__das__form">
        {CNPJ()}
        {Ano()}
      </div>
      {!!props.Button && props.Button}
    </div>}
  </>)
}

export default memo(FormularioDAS);