import React, {useContext, useEffect} from 'react'

import Modulo1 from '../../components/modulos/modulo1'
import Preferencias from '../../components/modulos/modulo5'
import Favorites from '../../components/modulos/favoritos'
import CursosRelacionados from '../../components/modulos/cursosRelacionados'
import Footer from '../../components/shared/footer'
import Header from '../../components/shared/header'
import Axios from 'axios'


import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import {Context, useGlobalState} from '../../components/shared/context/AppContext'
import Destaques from '../../components/shared/area-logada/destaques-estaduais'
import getMainJs from '../../services/Api_mainjs'
import ConteudosRelacionados from '../../components/modulos/conteudosRelacionados'

const Portal = () => {

  const {keycloak} = useContext(Context)
  const [state, dispatch] = useGlobalState()


  useEffect(() => {
    if (state.isRendered.modulo1 && state.isRendered.header && !state.isRendered.libLoaded) {
      let isRendered = state.isRendered
      isRendered['libLoaded'] = true
      dispatch({isRendered: isRendered})
      getMainJs(state.env.REACT_APP_WEM_URL)
      document.body.style.setProperty('cursor', 'unset')
      document.body.style.setProperty('opacity', '1', 'important')
    }
  }, [state])

  return (
    <div>
      <Header keycloak={keycloak}/>
      <Modulo1 keycloak={keycloak}/>
      <Footer/>
    </div>
  )
}

export default Portal
