import {getCacheDate} from "../date";
import axios from "axios";

const getMainJs = (wemUrl) => {

  let script = document.createElement('script')
  script.id = 'mainjs'
  script.src = './js/sb-main.js?v=' + getCacheDate()
  document.body.appendChild(script)

  script = document.createElement('script')
  script.id = 'locationjs'
  script.src = './js/location.js?v=' + getCacheDate()
  document.body.appendChild(script)

  window.urlAmbiente = wemUrl;

  script = document.getElementById("gtm-head")
  script.src = wemUrl + '/Sebrae/Portal Sebrae/resources/js/gtm/snippet-gtm-sebrae-head.js?v=' + getCacheDate()

  let gtmHtml = document.getElementById("gtm-body");
  let r = new XMLHttpRequest();
  r.open("GET", wemUrl + '/Sebrae/Portal%20Sebrae/resources/js/gtm/snippet-gtm-sebrae-body.html', true);
  r.onreadystatechange = function () {
    if (r.readyState != 4 || r.status != 200) return;
    gtmHtml.innerHTML = r.responseText;
  };
  r.send();
}

export default getMainJs
