
const getLSBoleto = () => {
    return localStorage.getItem("LSBoletos")? JSON.parse(localStorage.getItem("LSBoletos")) : null
};

export const pushAndCheck = (boleto) => {
  let lsBoletos = getLSBoleto();
  let pushed = false;
  if(!lsBoletos) {
    lsBoletos = {
        today: new Date().toLocaleDateString("pt-BR"),
        boletos: [],
    }
  }
  if (lsBoletos.today !== new Date().toLocaleDateString("pt-BR")) {
    lsBoletos.today = new Date().toLocaleDateString("pt-BR");
    lsBoletos.boletos = [];
    localStorage.removeItem("LSBoletos");
  }
  if(lsBoletos.boletos.indexOf(boleto) === -1) {
    lsBoletos.boletos.push(boleto);
    pushed = true;
  }
  localStorage.setItem("LSBoletos", JSON.stringify(lsBoletos));
  return pushed;
};