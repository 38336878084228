import React from 'react'

const Child = (props) => {
  return (
    <div className='sb-modal__wrapper'>
      <div className='sb-modal-content'>
        <div className='sb-modal-header'>
          <strong>O Portal Sebrae é feito para você!</strong>
          <span className='sb-components__modal__close-no-action-perfil' onClick={props.closeModalIncentivo}
                onKeyPress={props.closeModalIncentivo} data-sb-acao="clique" data-sb-rotulo="fechar"></span>
        </div>
        {props.optionsRender === 'select' ?
          <div className='sb-modal-body'>
            <p>Selecione o seu perfil e tenha uma experiência personalizada :)</p>
            <div className='sb-components-form__select sb-mb-20 '>
              {props.dominios}
            </div>
            {props.optionsMenu}
          </div>
          :
          <div className='sb-modal-body'>
            <div className='sb-components__alert success '>
              <strong>Perfil de cliente associado com sucesso!</strong>
            </div>
            <br/>
            <a className='sb-components-button inline small radius-5 text-center'
               onClick={props.closeModalIncentivo} onKeyPress={props.closeModalIncentivo} 
               data-sb-acao="clique" data-sb-rotulo="fechar">Fechar</a>
          </div>
        }
      </div>
    </div>
  )
}

export default Child
