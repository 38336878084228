import React, {createContext} from 'react'
import PropTypes from 'prop-types'
import {getCacheDate} from '../../../../src/date';

export const Context = createContext({})
export const dispatchStateContext = createContext(undefined)

export const useGlobalState = () => [
  React.useContext(Context),
  React.useContext(dispatchStateContext),
]


export const Provider = (props) => {
  // Initial values are obtained from the props
  const {
    keycloak,
    children,
    env,
  } = props


  // Make the context object:
  const appContext = {
    keycloak,
    isRendered: {
      modulo1: false,
      header: false,
      modulo5: true,
      conteudosParaVoce: false,
      modulo6: false,
      favoritos: false,
      modulo8: true,
      perguntasRespostas: false,
      conteudosRelacionados: false,
      cursosRelacionados: false,
      perguntasRespostas: false,
      libLoaded: false,
    },
    envURL: `${window.location.protocol}//${window.location.host}/env.json`,
    env,
  }

  const [state, dispatch] = React.useReducer(
    (state, newValue) => ({...state, ...newValue}),
    appContext,
  )

  // pass the value in provider and return
  return <Context.Provider value={state}>
    <dispatchStateContext.Provider value={dispatch}>
      {children}
    </dispatchStateContext.Provider>
  </Context.Provider>
}

export const {Consumer} = Context

Provider.propTypes = {
  keycloak: PropTypes.shape({}).isRequired,
  children: PropTypes.shape({}).isRequired,
}
