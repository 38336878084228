import React from "react";
import Empresas from "../modalCadastroPJ/Empresas";

function ModalBody({
  alert,
  bind,
  closeModal,
  empresas,
  state,
  definirComoPrincipal,
  setDefinirComoPrincipal,
  removerCnpj
}) {
  return (
    <div className="sb-utilities__clearfix">
      <p>
        Identificamos que você é Proprietário ou Sócio da(s) empresa(s) abaixo.
        Clique em "Confirmar" para completar a sua Conta Sebrae digital e ter
        acesso à serviços exclusivos para pessoas jurídicas.
      </p>
      <div class="sb-components-accordion">
        <ul>
          <Empresas
            empresas={empresas}
            state={state}
            definirComoPrincipal={definirComoPrincipal}
            setDefinirComoPrincipal={setDefinirComoPrincipal}
            removerCnpj={removerCnpj}
          />
        </ul>
      </div>
      <div class="modal-cad-pj__text-info">
        <p>
          * O Sebrae utiliza informações do Cadastro Nacional de Pessoa Jurídica
          da Receita Federal. Se Houver qualquer divergência, providencie junto
          à RFB a sua atualização cadastral.
        </p>
      </div>

      {alert}
      <div className="sb-components__form__buttons">
        <a
          href="#modal-vinculo-tag"
          className="sb-components__button inline radius-4"
          onClick={bind}
          data-sb-acao='clique'
          data-sb-rotulo='confirmar'
        >
          Confirmar
        </a>
        <a
          href="#"
          className="sb-components__button outline radius-4"
          onClick={closeModal}
          data-sb-acao='clique'
          data-sb-rotulo='voltar'
        >
          Voltar
        </a>
      </div>
    </div>
  );
}

export default ModalBody;