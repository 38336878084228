import React, { useEffect, useState } from 'react'
import { useGlobalState } from '../../context/AppContext'
import $ from 'jquery';
import Axios from 'axios'
import updateToken from "../../../../updateToken";
import OwlCarousel from 'react-owl-carousel';
import validateWebinar from "../../utils/validate-webinar";


const Conteudos = (props) => {

  const { keycloak } = props

  const [state, dispatch] = useGlobalState()
  const [uf] = useState(keycloak.tokenParsed.uf.trim())
  const [conteudos, setConteudos] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [hasContent, setHasContent] = useState(true)

  async function loadFavoritos() {

    $("#conteudos-para-voce .sb-components__favorite__content").on('click', function (e) {
      e.preventDefault();
      favoritar($(this));
    });
    const envs = state.env;
    const hostname = envs.BACKEND_URL

    Axios.get(`${hostname}/v1/favoritos/${keycloak.tokenParsed.sub}`)
      .then(res => {
        let favorites = res.data;
        $("#conteudos-para-voce .sb-components__favorite__content").each((index, item) => {
          let conteudoGuid = $(item).find('a.sb-components__favorite__content__icon').attr('codigo');
          if (favorites.includes(conteudoGuid)) {
            $(item).addClass('active')
          }
        })
      })

  }

  async function favoritar(element) {
    let conteudoGuid = $(element).find('a.sb-components__favorite__content__icon').attr('codigo');
    updateToken(keycloak, async function () {
      const envs = state.env;

      const hostname = envs.BACKEND_URL

      const path = '/v1/favoritos'

      const usuarioSub = keycloak.tokenParsed.sub

      async function isFavorito() {
        const { data, status } = await Axios.get(hostname + path + `/${usuarioSub}/0`)

        if (status === 200)
          return data.items.some((curso) => curso.id === conteudoGuid);

        return false
      }

      const isFav = await isFavorito()

      if (isFav) {
        const { status } = await Axios.delete(hostname + path + `/${usuarioSub}/${conteudoGuid}`)

        if (status === 200)
          $(element).removeClass('active')

      } else {
        const { status } = await Axios.post(hostname + path, { conteudoGuid, usuarioSub })

        if (status === 200)
          $(element).addClass('active')

      }

    });


  }


  function reqConteudos(url, uf) {
    const estados = {
      'AC': '1', 'AL': '2', 'AM': '4', 'AP': '3', 'BA': '5', 'CE': '6',
      'DF': '7', 'ES': '8', 'GO': '10', 'MA': '11', 'MG': '14', 'MS': '13',
      'MT': '12', 'PA': '15', 'PB': '16', 'PE': '18', 'PI': '19', 'PR': '17',
      'RJ': '20', 'RN': '21', 'RO': '23', 'RR': '9', 'RS': '22', 'SC': '25',
      'SE': '27', 'SP': '26', 'TO': '24',
    }


    Axios.get(`${url}/v1/conteudosParaVoce?codUf=${estados[uf]}`)
      .then(res => {
        setConteudos(res.data)
        setLoaded(true);
        let isRendered = state.isRendered
        isRendered['conteudosParaVoce'] = true
        dispatch({ isRendered: isRendered })
      })
      .catch(() => {
        let isRendered = state.isRendered
        isRendered['conteudosParaVoce'] = true
        dispatch({ isRendered: isRendered })
      })
  }

  useEffect(() => {
    if($("#conteudos-para-voce .sb-components__favorite__content").length > 0 && !$("#conteudos-para-voce").is(":visible")){
      $("#conteudos-para-voce").show();
      loadFavoritos();
      validateWebinar(state);
    }
  });

  useEffect(() => {
    reqConteudos(state.env.BACKEND_URL, uf);
  }, [])

  return loaded ?
    hasContent ?
      <section className='sb-area-logada__menu-lateral__carrossel sb-amei-conteudos-para-voce' id="conteudos-para-voce" style={{display:'none'}}>
        <h2>Conteúdos para você</h2>
        <div className="sb-components-carousel overflow-x-inherit-md">
          <OwlCarousel
            className="owl-carousel owl-theme"
            margin={10}
            navText={['<span class="sb-ico-angle-left"></span>', '<span class="sb-ico-angle-right"></span>']}
            nav={true}
            responsive={{0: {items: 1, slideBy: 1, autoWidth: true, margin: 24}, 1025: {items: 3, slideBy: 3, nav: true}}}
            dangerouslySetInnerHTML={{__html: conteudos}}/>
        </div>
      </section>
      :
      <></>
    :
    <div className="sb-loading"><span/></div>
}

export default Conteudos
