import React from "react";
import ErrorModal from "./ErrorModal";
import LoadingModal from "./LoadingModal";
import ModalBody from "./ModalBody";
import SuccessModal from "./SuccessModal";

const Child = ({
  bindStatus,
  isLoading,
  alert,
  bind,
  closeModal,
  empresas,
  state,
  definirComoPrincipal,
  setDefinirComoPrincipal,
  handleAdicionarOutroCnpj,
  removerCnpj
}) => {
  return (
    <div className="sb-components__modal__wrapper">
      <div className="sb-components__modal__wrapper__content">
        <span
          className="sb-components__modal__close"
          onClick={closeModal}
          data-sb-acao='clique'
          data-sb-rotulo='fechar'
        ></span>
        <span className="sb-components__modal__wrapper__content__title__h1 sb-components__modal__wrapper__content__title__h1-blue">
          Adicionar CNPJ
        </span>
        {isLoading ? (
          <LoadingModal />
        ) : bindStatus == "success" ? (
          <div className="sb-utilities__clearfix">
          <SuccessModal
            closeModal={closeModal}
            handleAdicionarOutroCnpj={handleAdicionarOutroCnpj}
          />
          </div>
        ) : bindStatus.includes("failed") ? (
          <div className="sb-utilities__clearfix">
          <ErrorModal
            message={bindStatus.substring(bindStatus.indexOf(';')+1)}
            closeModal={closeModal}
            handleAdicionarOutroCnpj={handleAdicionarOutroCnpj}
          />
          </div>
        ) : (
          <ModalBody
            empresas={empresas}
            state={state}
            definirComoPrincipal={definirComoPrincipal}
            setDefinirComoPrincipal={setDefinirComoPrincipal}
            alert={alert}
            bind={bind}
            closeModal={closeModal}
            removerCnpj={removerCnpj}
          />
        )}
      </div>
    </div>
  );
};

export default Child;
