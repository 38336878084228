import React from "react";

export default function LoadingModal() {
  return (
    <div class="sb-utilities__clearfix">
      <div className="sb-components__alert">
        <div className="sb-utilities__text-center">
          <img
            width="160px"
            alt=""
            src="/dist/gifs/sb-loading-default.gif"
          />
        </div>
      </div>
      <div className="sb-components__form__buttons"></div>
    </div>
  );
}
