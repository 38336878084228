import { useState } from "react";
import axios from "axios";
import { cleanCnpj } from '../utils/cnpjHelper';
import { isBoletoVencido } from "../utils/boletoHelper";


export default function useBoletos({url}) {
  const [lastResult, setLastResult] = useState({key: '', value: {}});
  const [boletos, setBoletos] = useState({});
  const [checkedBoletos, setCheckedBoletos] = useState([]);

  function processBoletos(data) {
    const pagos = [];
    const naoPagos = [];
    data.forEach(boleto => {
      boleto.codigoBarrasFull =
      boleto.codigoBarras1 +
      boleto.codigoBarras2 +
      boleto.codigoBarras3 +
      boleto.codigoBarras4;
      if (!!boleto.stPagamentoBoleto) {
        pagos.push({
          ...boleto,
          situacao: 'pago'
        });
      } else {
        let situacao = 'a vencer';
        if(parseInt(boleto.codigoRetorno) !== 0) situacao = 'indisponivel';
        else if(isBoletoVencido(boleto.dataVencimento)) situacao = 'atrasado';
        naoPagos.push({
          ...boleto, 
          situacao,
          isDisponivel: parseInt(boleto.codigoRetorno) === 0
        });
      }
    })
    return {pagos: pagos, naoPagos: naoPagos};
  }

  function fetchBoletos({cnpj, ano}, callback, attempt=2) {
    if(lastResult.key === `${cleanCnpj(cnpj)}_${ano}`) {
      setBoletos(lastResult.value);
      if(callback) {
        callback()
      }
      return;
    }

    axios.get(`${url}/v1/boleto/getBoletosAno/${cleanCnpj(cnpj)}/${ano}`)
    .then(response => {
      const boletosProcessados = processBoletos(response.data)
      setBoletos(boletosProcessados);
      setLastResult({key:`${cleanCnpj(cnpj)}_${ano}`, value: boletosProcessados})
    })
    .then(!!callback? callback : true)
    .catch(err => { 
      console.error("ocorreu um erro: " + err);
      if(attempt > 0) {
        fetchBoletos({cnpj, ano}, callback, attempt-1);
      }
    });
  }

  return [boletos, setBoletos, fetchBoletos, checkedBoletos, setCheckedBoletos];
}
