import React from "react";
import { useGlobalState } from "../../shared/context/AppContext";


function EmpresaCard({
  nomeFantasia,
  razaoSocial,
  cnpj,
  cpfPessoaResponsavel,
  socios,
  porte,
  isEmpresaParceira,
  desTipoVinculo,
  principal,
  empresaChangeHandler,
  desvincularPj

}) {

  const [state] = useGlobalState();


  function formatCnpj(cnpj = "") {
    if (!cnpj || cnpj.length !== 14) return cnpj;
    return cnpj.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      "$1.$2.$3/$4-$5"
    );
  }
  function getTipoVinculo({ cpfPessoaResponsavel, socios = [] }) {
    const cpf = state.keycloak.tokenParsed && state.keycloak.tokenParsed.cpf
      ? state.keycloak.tokenParsed.cpf.replaceAll(/\D/g, '')
      : "";
    return cpfPessoaResponsavel === cpf ||
      (socios && socios.find((s) => s.cpf === cpf))
      ? "Proprietário ou Sócio"
      : "Representante";
  }
  function getPorte(porte) {
    const EMPRESA_TIPO = [
      { valor: 1, descricao: "Média Empresa" },
      { valor: 2, descricao: "Microempresa" },
      { valor: 3, descricao: "Empresa de Pequeno Porte - EPP" },
      { valor: 4, descricao: "Grande Empresa" },
      { valor: 5, descricao: "Não Aplicável" },
      { valor: 99, descricao: "Microempreendedor Individual" },
    ];
    const porteObj = EMPRESA_TIPO.find((p) => p.valor === porte);
    return porteObj ? porteObj.descricao : "";
  }
  function getEmpresaParceira(isEmpresaParceira) {
    return isEmpresaParceira == "true" ? "Empresa parceira" : "";
  }
  function formatDesTipoVinculo(desTipoVinculo) {
     return desTipoVinculo.split(' ')
                .map(letter => letter[0].toUpperCase() === "O" ? letter[0].toLowerCase() + letter.substring(1).toLowerCase() : letter[0].toUpperCase() + letter.substring(1).toLowerCase())
                .join(' ')
  }

  return (
    <>
      <div
        class="sb-area-logada__menu-lateral__suas-empresas__item"
        key={cnpj}
      >
		<div class="sb-area-logada__menu-lateral__suas-empresas__item__title">
		  {isEmpresaParceira === 'true' ? (
            <p class="modalp">{getEmpresaParceira(isEmpresaParceira)}</p>
          ) : ''}
          <strong>{nomeFantasia || razaoSocial}</strong>
          <span>{formatCnpj(cnpj)}</span>
        </div>
        <p>{formatDesTipoVinculo(desTipoVinculo)}</p>
        <p>{getPorte(porte)}</p>
        <div class="sb-area-logada__menu-lateral__suas-empresas__item__buttons">
          {cnpj == principal ? (
            <button
              type="button"
              className="modal-cad-pj__favorite-button active"
            >
              Prioritário
            </button>
          ) : (
            <button
              type="button"
              className="modal-cad-pj__favorite-button"
              onClick={() => empresaChangeHandler(cnpj)}
              onKeyPress={() => empresaChangeHandler(cnpj)}
            >
              Definir como prioritário
            </button>
          )}
          <button type="button" class="modal-cad-pj__trash-button btn-card-top" onClick={() => desvincularPj(cnpj)}>
            Excluir
          </button>
        </div>
      </div>
    </>
  );
}

export default EmpresaCard