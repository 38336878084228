import axios from 'axios'
import {getUrlApi} from './Api'

const getUrlFooter = () => {
  getUrlApi('BACKEND_URL').then((url) => {
    axios.get(`${url}/v1/footer`)
      .then(res => {
        document.getElementById('footer').innerHTML = res.data
      }).catch(err => {
      console.log('ocorreu um erro ' + err)
    })
  })
}

export default getUrlFooter
