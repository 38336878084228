import React, { useEffect, useState } from 'react'
import stringToHTML from '../../shared/utils/html-utils';
import Axios from 'axios'
import $ from 'jquery';
import { useGlobalState } from '../../shared/context/AppContext'
import OwlCarousel from "react-owl-carousel";

import updateToken from '../../../updateToken'
import validateWebinar from "../../shared/utils/validate-webinar";

let params = {
  path_favorites: 'v1/favoritos/',
  card: 'v1/card/conteudo/',
  pageSize: 3,
}


const Favorites = (props) => {
  const { keycloak } = props

  const [json, setJson] = useState(null)
  const [isMobile] = useState(window.innerWidth < 992)
  const [cardItems, setCards] = useState('')
  const [state, dispatch] = useGlobalState()
  const [loaded, setLoaded] = useState(false)
  const [loadedMore, setLoadedMore] = useState(false)

  useEffect(() => {
    params.clientSecret = keycloak.tokenParsed.sub
    if (isMobile) params.pageSize = 0

    params.urlWEM = state.env.REACT_APP_WEM_URL
    params.server = state.env.BACKEND_URL
    if (!params.server.endsWith('/')) {
      params.server += '/'
    }
    params.pathImage = state.env.REACT_APP_WEM_URL
    if (!params.pathImage.endsWith('/')) {
      params.pathImage += '/'
    }

    getContent()
  }, [])

  async function getCardById(vcmId) {
    const card = await Axios.get(params.server + params.card + vcmId).then(res => {
      return convertHTMLToElement(res.data.trim());
    }).catch(() => {
      let isRendered = state.isRendered
      isRendered['favoritos'] = true
      dispatch({ isRendered: isRendered })
    })

    return await card;
  }

  async function getCardsByJson(json){
    if(json && json.items && json.items.length){
      let cardsJson = json.items;

      let cards = [];

      for(let i=0;i<cardsJson.length;i++) {
        cards.push(await getCardById(cardsJson[i]));
      }
      return await cards;
    }
  }

  function getContent() {
    let url = params.server + params.path_favorites + params.clientSecret + '/' + params.pageSize;
    
    Axios.get(url).then(res => {
      setJson(res.data)
      getCardsByJson(res.data).then(cardsRendered => {
        setLoaded(true)
        setLoadedMore(false)
        if(cardsRendered){
          setCards(<>{cardsRendered.map((o,index) => {
              if(o && o.outerHTML){
                return <div className="item" key={`card-`+index}>
                    <div dangerouslySetInnerHTML={{__html: o.outerHTML}} />
                </div>
              }
              return <></>
            })}
          </>)
          loadAndDesfavorite();
          validateWebinar(state)
        }
      })

      let isRendered = state.isRendered
      isRendered['favoritos'] = true
      dispatch({ isRendered: isRendered })
    }).catch(() => {
      let isRendered = state.isRendered
      isRendered['favoritos'] = true
      dispatch({ isRendered: isRendered })
    })
  }

  function loadMore() {
    setLoadedMore(true)
    params.pageSize += 3
    getContent()
  }

  function loadAndDesfavorite(){
    $('#cards-favoritados .sb-components__favorite__content').addClass('active');
    $('#cards-favoritados .sb-components__favorite__content a').on('click', function(e){
      e.preventDefault();
      let id = this.attributes.codigo.value;
      let url = params.server + params.path_favorites + params.clientSecret + '/' + id
      updateToken(keycloak, function () {
        Axios.delete(url)
          .then(() => {
            getContent()
          }).catch(err => {
            return err
          })
      });
    })
  }

  function convertHTMLToElement(html){
    return $(stringToHTML(html)).children().first().get(0)
  }
  
  return loaded ?
    <section className="sb-area-logada__menu-lateral__carrossel" id="cards-favoritados">
      <h2>

          Meus favoritos
        </h2>

        <div className="sb-components__carousel overflow-x-inherit-md">
          {isMobile ?
            (!json || !json.items || !json.items.length || cardItems.length == 0) ?
              <div className="item">
                <img src="/dist/images/sb-components/sb-imagem-sem-favorito.png" alt=""/>
              </div>
              :
              <OwlCarousel
                autoWidth={false}
                items={1}
                slideBy={1}
                margin={12}
                className="owl-carousel owl-theme">
                  {cardItems}
              </OwlCarousel>
            :
            <div
              className="owl-carousel owl-theme">
              {(!json || !json.items || !json.items.length || cardItems.length == 0)
                ?
                <div className="item">
                  <img src="/dist/images/sb-components/sb-imagem-sem-favorito.png" alt=""/>
                </div>
                :
                cardItems
              }
            </div>
          }

        </div>

        {(json && json.items && json.items.length && json.hasMore && !isMobile)
          ?
            (loadedMore ?
                <div className="sb-loading"><span/></div>
            :
            <div className="sb-utilities__text-center">
              <a href={void (0)} onClick={loadMore} onKeyPress={loadMore}
                className="sb-components__button inline radius-4">Carregar mais</a>
            </div>)
          :
          ''
        }
    </section>
    :
    <div className="sb-loading"><span/></div>

}

export default Favorites
